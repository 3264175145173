import { useNavigate } from "react-router-dom";
import NavButton from "../NavButton";
import { AiFillHome, AiFillStar } from 'react-icons/ai';
import styles from './styles.module.css';

type Props = {
  isNavMenuMinimized?: boolean;
}

export default function NavButtons({ isNavMenuMinimized }: Props) {
  const navigate = useNavigate();

  return (
    <div className={styles.navButtonsContainer}>
      <div className={styles.navButtonsRowContainer}>
        <NavButton
          title={isNavMenuMinimized ? "" : "Home"}
          Icon={AiFillHome}
          iconColor="#133C46"
          onClick={() => navigate("/home")}
          isSelected={window.location.pathname === "/home"}
        />
        <NavButton
          title={isNavMenuMinimized ? "" : "Activities"}
          Icon={AiFillStar}
          iconColor="#133C46"
          onClick={() => navigate("/activities")}
          isSelected={window.location.pathname === "/activities"}
        />
      </div>
    </div>
  );
}