import styles from "./styles.module.css";
import CogSelectedIcon from "../../../assets/connect-button/cog_selected.svg";
import MartySelectedIcon from "../../../assets/connect-button/marty_selected.svg";
import { ReactComponent as ConnectButtonDefault } from "../../../assets/connect-button/connect_btn-default.svg";

import RobotButton from "./RobotButton";
import { useVisibleContent } from "../../../store/VisibleContentContext";
import { NewRobotIdE, useConnectedRafts } from "../../../store/SelectedRaftContext";
import { FaPlus } from "react-icons/fa";
import { RaftTypeE } from "../../../types/raft";
import { ConnManager } from "@robotical/roboticaljs";

type Props = {
    isNavMenuMinimized: boolean;
};

const SHOW_LOGS = true;
const TAG = "ConnectionArea";

const ConnectionArea: React.FC<Props> = ({ isNavMenuMinimized }) => {
    const { setVisibleContent } = useVisibleContent();
    const { connectedRafts, setSelectedRaft } = useConnectedRafts();
    const selectedRaft = connectedRafts.find((connectedRaft) => connectedRaft.isSelected);

    const onClickConnect = async () => {
        window.applicationManager.connectGeneric((newRaft) => {
            setVisibleContent(newRaft.type, true);
        }, [ConnManager.COGUUID, ConnManager.RICUUID]);
    };

    const onClickDisconnect = async (raftId: string) => {
        await window.applicationManager.disconnectGeneric(window.applicationManager.connectedRafts[raftId]);
    };

    return <div className={[styles.connectionAreaContainer].join(" ")}>
        <div className={[styles.connectionAreaContainerInner, isNavMenuMinimized ? styles.minimizedConnectionAreaInnerContainer : ""].join(" ")}>
            {/* Connection button */}
            {connectedRafts.filter(raft => raft.id !== NewRobotIdE.NEW).length === 0 && <div className={styles.connectButtonContainer} onClick={onClickConnect}>
                <div className={styles.emptyRow}></div>
                <div className={styles.connectButtonIconContainer}>
                    <img src={CogSelectedIcon} alt="Cog icon" className={styles.connectButtonIcon} />
                    <img src={MartySelectedIcon} alt="Marty icon" className={styles.connectButtonIcon} />
                </div>
                <div className={[styles.connectIconContainer, isNavMenuMinimized ? styles.minimizedConnectIconContainer : ""].join(" ")}>
                    <ConnectButtonDefault />
                </div>
            </div>}
            {/* End of connection button */}
            {isNavMenuMinimized ? null :
                connectedRafts.map((connectedRaft) => {
                    if (connectedRaft.id === NewRobotIdE.NEW) return null;
                    return <RobotButton
                        key={connectedRaft.id}
                        robotType={connectedRaft.type}
                        connectedRaft={window.applicationManager.connectedRafts[connectedRaft.id]}
                        isSelected={connectedRaft.isSelected}
                        onClickDisconnect={onClickDisconnect}
                        setSelectedRaft={setSelectedRaft}
                    />
                })
            }
            {connectedRafts.filter(raft => raft.id !== NewRobotIdE.NEW).length > 0 && <div className={styles.plusIconContainer} onClick={onClickConnect}>
                <FaPlus className={styles.plusIcon} />
            </div>}
        </div>
    </div >
}

export default ConnectionArea;