import Logger from "../../services/logger/Logger";

const SHOW_LOGS = true;
const TAG = "SelfdestructiveMessagePromise";

export default class SelfdestructiveMessagePromise {
    resolve: (value?: any) => void = () => { };
    reject: (reason?: any) => void = () => { };
    timeout: NodeJS.Timeout;
    TIMEOUT_PERIOD: number = 30000;



    constructor(
        resolve: (value?: any) => void,
        reject: (reason?: any) => void,
        id: string,
        messagePromises: { [key: string]: SelfdestructiveMessagePromise | undefined },
    ) {
        this.resolve = (value?: any) => {
            resolve(value);
            clearTimeout(this.timeout);
            delete messagePromises[id];
        }
        this.reject = (reason?: any) => {
            resolve(reason); // on purpose resolving instead of rejecting
            clearTimeout(this.timeout);
            delete messagePromises[id];
        }
        this.timeout = setTimeout(() => {
            if (this.reject) {
                Logger.warn(SHOW_LOGS, TAG, `Timeout for message promise with id: ${id}`);
                this.reject({ error: "Timeout" });
                delete messagePromises[id];
            }
            clearTimeout(this.timeout);
        }, this.TIMEOUT_PERIOD);
    }
}