import { RaftConnEvent, RaftUpdateEvent } from "@robotical/raftjs";
import { RaftInfoEvents } from "../../types/events/raft-info";
import RAFT from "./RAFT";

type ObserverType = {
    notify: (eventType: string, eventEnum: RaftConnEvent | RaftUpdateEvent | RaftInfoEvents, eventName: string, eventData: any) => void;
}
/**
 * Connection Verifying Subscription Helper
 */
export const raftConnectingSubscriptionHelper = (raft: RAFT) => {
    let observer: ObserverType | null = null;
    return {
        subscribe: (callback: any) => {
            observer = raftConnectingSubscriptionObserver_(callback);
            raft.subscribe(observer, ["conn"]);
        },
        unsubscribe: () => {
            if (observer) {
                raft.unsubscribe(observer);
            }
        }
    };
};

const raftConnectingSubscriptionObserver_ = (callback: (data: any) => void) => {
    return {
        notify(
            eventType: string,
            eventEnum: RaftConnEvent | RaftUpdateEvent | RaftInfoEvents,
            eventName: string,
            eventData: any
        ) {
            switch (eventType) {
                // case "conn":
                //     switch (eventEnum) {
                //         case RaftConnEvent.BLE_VERIFYING_CORRECT:
                //             callback(eventData.bondingColors);
                //             break;
                //         default:
                //             break;
                //     }
                //     break;
                case "conn":
                    switch (eventEnum) {
                        case RaftConnEvent.CONN_VERIFYING_CORRECT:
                            callback(eventData);
                            break;
                        default:
                            break;
                    }
                    break;
            }
        },
    };
};

/**
 * RAFT Connected Subscription Helper
 */
export const raftConnectedSubscriptionHelper = (raft: RAFT) => {
    let observer: ObserverType | null = null;
    return {
        subscribe: (callback: any) => {
            observer = raftConnectedSubscriptionObserver_(callback);
            raft.subscribe(observer, ["conn"]);
        },
        unsubscribe: () => {
            if (observer) {
                raft.unsubscribe(observer);
            }
        }
    };
};

const raftConnectedSubscriptionObserver_ = (callback: any) => {
    return {
        notify(
            eventType: string,
            eventEnum: RaftConnEvent | RaftUpdateEvent | RaftInfoEvents,
            eventName: string,
            eventData: any
        ) {
            switch (eventType) {
                // case "conn":
                //     switch (eventEnum) {
                //         case RaftConnEvent.BLE_CONNECTED:
                //             console.log("Marty Connected!!!!!!!!!");
                //             callback();
                //             break;
                //         default:
                //             break;
                //     }
                //     break;
                case "conn":
                    switch (eventEnum) {
                        case RaftConnEvent.CONN_CONNECTED:
                            console.log("Marty Connected!!!!!!!!!");
                            callback();
                            break;
                        default:
                            break;
                    }
                    break;
            }
        },
    };
};

/** 
 * RAFT Disconnected Subscription Helper
 */
export const raftDisconnectedSubscriptionHelper = (raft: RAFT) => {
    let observer: ObserverType | null = null;
    return {
        subscribe: (callback: any) => {
            observer = raftDisconnectedSubscriptionObserver_(callback);
            raft.subscribe(observer, ["conn"]);
        },
        unsubscribe: () => {
            if (observer) {
                raft.unsubscribe(observer);
            }
        }
    }
}

const raftDisconnectedSubscriptionObserver_ = (callback: any) => {
    return {
        notify(
            eventType: string,
            eventEnum: RaftConnEvent | RaftUpdateEvent | RaftInfoEvents,
            eventName: string,
            eventData: any
        ) {
            switch (eventType) {
                // case "conn":
                //     switch (eventEnum) {
                //         case RaftConnEvent.BLE_DISCONNECTED:
                //             console.log("Marty Disconnected!!!!!!!!!");
                //             callback();
                //             break;
                //         default:
                //             break;
                //     }
                //     break;
                case "conn":
                    switch (eventEnum) {
                        case RaftConnEvent.CONN_DISCONNECTED:
                            console.log("Marty Disconnected!!!!!!!!!");
                            callback();
                            break;
                        default:
                            break;
                    }
                    break;
            }
        },
    };
};

/**
 * RAFT Verified Subscription Helper
 */
export const raftVerifiedSubscriptionHelper = (raft: RAFT) => {
    let observer: ObserverType | null = null;
    return {
        subscribe: (callback: any) => {
            observer = raftVerifiedSubscriptionObserver_(callback);
            raft.subscribe(observer, ["conn"]);
        },
        unsubscribe: () => {
            if (observer) {
                raft.unsubscribe(observer);
            }
        }
    };
};

const raftVerifiedSubscriptionObserver_ = (callback: any) => {
    return {
        notify(
            eventType: string,
            eventEnum: RaftConnEvent | RaftUpdateEvent | RaftInfoEvents,
            eventName: string,
            eventData: any
        ) {
            switch (eventType) {
                // case "conn":
                //     switch (eventEnum) {
                //         case RaftConnEvent.BLE_VERIFIED_CORRECT:
                //             callback();
                //             break;
                //         default:
                //             break;
                //     }
                //     break;
                case "conn":
                    switch (eventEnum) {
                        case RaftConnEvent.CONN_VERIFIED_CORRECT:
                            callback();
                            break;
                        default:
                            break;
                    }
                    break;
            }
        },
    };
};


/**
 * Subscription Helper for raftFound event
 * ApplicationManager
 */
export const raftFoundSubscriptionHelper = () => {
    let observer: ObserverType | null = null;
    return {
        subscribe: (callback: any) => {
            observer = raftFoundSubscriptionObserver_(callback);
            window.applicationManager.subscribe(observer, ["conn"]);
        },
        unsubscribe: () => {
            if (observer) {
                window.applicationManager.unsubscribe(observer);
            }
        }
    }
}

const raftFoundSubscriptionObserver_ = (callback: any) => {
    return {
        notify(
            eventType: string,
            eventEnum: RaftConnEvent | RaftUpdateEvent | RaftInfoEvents,
            eventName: string,
            eventData: any
        ) {
            switch (eventType) {
                case "conn":
                    switch (eventEnum) {
                        case RaftConnEvent.BLE_DEVICE_FOUND:
                            callback(eventData);
                            break;
                        default:
                            break;
                    }
                    break;
            }
        },
    };
};


/**
 * Subscription Helper for raftDiscoveryFinished event
 */
export const raftDiscoveryFinishedSubscriptionHelper = () => {
    let observer: ObserverType | null = null;
    return {
        subscribe: (callback: any) => {
            observer = raftDiscoveryFinishedSubscriptionObserver_(callback);
            window.applicationManager.subscribe(observer, ["conn"]);
        },
        unsubscribe: () => {
            if (observer) {
                window.applicationManager.unsubscribe(observer);
            }
        }
    }
}

const raftDiscoveryFinishedSubscriptionObserver_ = (callback: any) => {
    return {
        notify(
            eventType: string,
            eventEnum: RaftConnEvent | RaftUpdateEvent | RaftInfoEvents,
            eventName: string,
            eventData: any
        ) {
            switch (eventType) {
                case "conn":
                    switch (eventEnum) {
                        case RaftConnEvent.BLE_SCANNING_FINISHED:
                            callback(eventData);
                            break;
                        default:
                            break;
                    }
                    break;
            }
        },
    };
};

/**
 * Subscription Helper for raftDiscoveryStarted event
 */
export const raftDiscoveryStartedSubscriptionHelper = () => {
    let observer: ObserverType | null = null;
    return {
        subscribe: (callback: any) => {
            observer = raftDiscoveryStartedSubscriptionObserver_(callback);
            window.applicationManager.subscribe(observer, ["conn"]);
        },
        unsubscribe: () => {
            if (observer) {
                window.applicationManager.unsubscribe(observer);
            }
        }
    }
}

const raftDiscoveryStartedSubscriptionObserver_ = (callback: any) => {
    return {
        notify(
            eventType: string,
            eventEnum: RaftConnEvent | RaftUpdateEvent | RaftInfoEvents,
            eventName: string,
            eventData: any
        ) {
            switch (eventType) {
                case "conn":
                    switch (eventEnum) {
                        case RaftConnEvent.BLE_SCANNING_STARTED:
                            callback(eventData);
                            break;
                        default:
                            break;
                    }
                    break;
            }
        },
    };
};


/**
 * Subscription Helper for raftRejected event
 * This event is triggered when the RAFT is rejected during the verification process
 */
export const raftRejectedSubscriptionHelper = (raft: RAFT) => {
    let observer: ObserverType | null = null;
    return {
        subscribe: (callback: any) => {
            observer = raftRejectedSubscriptionObserver_(callback);
            raft.subscribe(observer, ["conn"]);
        },
        unsubscribe: () => {
            if (observer) {
                raft.unsubscribe(observer);
            }
        }
    };
};

const raftRejectedSubscriptionObserver_ = (callback: any) => {
    return {
        notify(
            eventType: string,
            eventEnum: RaftConnEvent | RaftUpdateEvent | RaftInfoEvents,
            eventName: string,
            eventData: any
        ) {
            switch (eventType) {
                case "conn":
                    switch (eventEnum) {
                        case RaftConnEvent.CONN_REJECTED:
                            callback();
                            break;
                        default:
                            break;
                    }
                    break;
                case "conn":
                    switch (eventEnum) {
                        case RaftConnEvent.CONN_REJECTED:
                            callback();
                            break;
                        default:
                            break;
                    }
                    break;
            }
        },
    };
};


export const raftPubSubscriptionHelper = (raft: RAFT) => {
    let observer: ObserverType | null = null;
    return {
        subscribe: (callback: (eventData: any) => void) => {
            observer = raftPubSubscriptionObserver_(callback);
            raft.subscribe(observer, ["raftinfo"])
        },
        unsubscribe: () => {
            if (observer) {
                raft.unsubscribe(observer);
            }
        }
    };
}

const raftPubSubscriptionObserver_ = (callback: any) => {
    return {
        notify(
            eventType: string,
            eventEnum: RaftConnEvent | RaftUpdateEvent | RaftInfoEvents,
            eventName: string,
            eventData: any
        ) {
            switch (eventType) {
                case "raftinfo":
                    switch (eventEnum) {
                        case "STATE_INFO":
                            callback(eventData);
                            break;
                        default:
                            break;
                    }
                    break;
            }
        },
    };
};

