import ReactDOM from 'react-dom/client';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// import { MartyConnectionContextProvider } from "./store/marty-connection-context";
// import { DefferedPromptContextProvider } from './store/deffered-prompt-installation';
import { VisibleContentProvier } from './store/VisibleContentContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from "./App";
import { ConnectedRaftContentProvider } from './store/SelectedRaftContext';
import { EmojiModalDisplayProvider } from './store/EmojiModalDisplayContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  // <DefferedPromptContextProvider>
  //   <MartyConnectionContextProvider>
  <ConnectedRaftContentProvider>
    <VisibleContentProvier>
      <EmojiModalDisplayProvider>
        <App />
      </EmojiModalDisplayProvider>
    </VisibleContentProvier>
  </ConnectedRaftContentProvider>
  //   </MartyConnectionContextProvider>
  // </DefferedPromptContextProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
