import { createContext, useContext, useState } from 'react';
import { RaftTypeE } from '../types/raft';

const VisibleContentContext = createContext({
  visibleContent: {
    [RaftTypeE.COG]: true,
    [RaftTypeE.MARTY]: true,
  },
  setVisibleContent: (visibleContent: RaftTypeE, visible: boolean) => {},
});

// Provider component
export const VisibleContentProvier = ({ children }: { children: React.ReactNode }) => {
  const [visibleContent, setVisibleContent_] = useState({
    [RaftTypeE.COG]: true,
    [RaftTypeE.MARTY]: true,
  });

  const setVisibleContent = (visibleContent: RaftTypeE, visible: boolean) => {
    setVisibleContent_((prev) => ({
      ...prev,
      [visibleContent]: visible,
    }));
  };

  return (
    <VisibleContentContext.Provider value={{ visibleContent, setVisibleContent: setVisibleContent }}>
      {children}
    </VisibleContentContext.Provider>
  );
};

export const useVisibleContent = () => useContext(VisibleContentContext);