import { FunctionComponent, SVGProps } from "react";
import "./styles.css";

type SVGImageButtonProps = {
  onClick: () => void;
  title: string;
  subtitle?: string;
  SVGImage: FunctionComponent<SVGProps<SVGSVGElement>>;
  backgroundColour?: string;
  titleColour?: string;
  SVGColour?: string;
  disabled?: boolean;
};

export default function SVGImageButton({
  onClick,
  title,
  subtitle,
  SVGImage,
  backgroundColour,
  titleColour,
  SVGColour,
  disabled,
}: SVGImageButtonProps) {
  return (
    <div
      className={
        subtitle
          ? "svg-image-button-container"
          : "svg-image-button-container-no-sub"
      }
      onClick={disabled ? () => {} : onClick}
      style={{ backgroundColor: disabled ? "#f0f0f0" : backgroundColour, cursor: disabled ? "not-allowed" : "pointer" }}
    >
      <p
        className={
          subtitle ? "svg-image-button-title" : "svg-image-button-title-no-sub"
        }
        style={{ color: titleColour }}
      >
        {title}
      </p>
      {subtitle && <p className="svg-image-button-subtitle">{subtitle}</p>}
      <div className="svg-image-button-image-container">
        <SVGImage fill={SVGColour} />
      </div>
    </div>
  );
}
