import { useEffect, useState } from 'react';
import { DraggableData, Rnd } from 'react-rnd';
import styles from './styles.module.css';
import draggableModalState from '../../../state-observables/modal/DraggableModalState';
import { FaTimes, FaWindowRestore, FaWindowMaximize, FaWindowMinimize, FaExternalLinkAlt } from 'react-icons/fa';

type Props = {
    children: React.ReactNode;
    modalClose: () => void;
    shouldCloseModal: boolean;
    title: string;
    newWindowLink?: string;
};

function DraggableResizableModal({ children, modalClose, shouldCloseModal, title, newWindowLink }: Props) {
    const [size, setSize] = useState<{ width: string | number; height: string | number }>({ width: '70%', height: '70%' });
    const [position, setPosition] = useState({ x: 50, y: 50 });
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    useEffect(() => {
        if (shouldCloseModal) {
            handleClose();
        }
    }, [shouldCloseModal]);

    const handleClose = () => {
        draggableModalState.closeModal();
        modalClose();
    };

    const handleRestore = () => {
        setSize({ width: 490, height: 360 });
    };

    const handleMaximize = () => {
        setSize({ width: '100%', height: '100%' });
        setPosition({ x: 0, y: 0 });
    };
    const handleMinimize = () => {
        setSize({ width: '32%', height: 40 });
        setPosition({ x: 0, y: 0 });
    };

    const handleDragStart = () => {
        setIsOverlayVisible(true);
    };

    const handleDragStop = (e: any, d: DraggableData) => {
        setIsOverlayVisible(false);
        setPosition({ x: d.x, y: d.y });
    };

    const handleResizeStart = () => {
        setIsOverlayVisible(true);
    };

    const handleResizeStop = (e: any, direction: any, ref: HTMLElement) => {
        setIsOverlayVisible(false);
        setSize({ width: ref.style.width, height: ref.style.height });
    };  

    const handleResize = (e: any, direction: any, ref: HTMLElement, delta: any, position: { x: number; y: number }) => {
        setSize({ width: ref.style.width, height: ref.style.height });
        setPosition({ x: position.x, y: position.y });
    };

    const openInNewWindow = () => {
        if (newWindowLink) {
            window.open(newWindowLink, "_blank");
        }
    };


    return (
        <>
            {isOverlayVisible && <div className={styles.overlay} />}
            <Rnd
                size={size}
                position={position}
                onDragStart={handleDragStart}
                onDragStop={handleDragStop}
                onResize={handleResize}
                onResizeStart={handleResizeStart}
                onResizeStop={handleResizeStop}
                dragHandleClassName={styles.modalHeader}
                className={styles.modal}
                enableResizing={{
                    top: false,
                    topRight: false,
                    topLeft: false,
                    bottom: true,
                    bottomRight: true,
                    bottomLeft: true,
                    right: true,
                    left: true,
                }}
                resizeHandleClasses={{
                    topLeft: styles.rndHandle + ' ' + styles.resizableHandleTl,
                    topRight: styles.rndHandle + ' ' + styles.resizableHandleTr,
                    bottomLeft: styles.rndHandle + ' ' + styles.resizableHandleBl,
                    bottomRight: styles.rndHandle + ' ' + styles.resizableHandleBr,
                    top: styles.rndHandle + ' ' + styles.resizableHandleT,
                    right: styles.rndHandle + ' ' + styles.resizableHandleR,
                    bottom: styles.rndHandle + ' ' + styles.resizableHandleB,
                    left: styles.rndHandle + ' ' + styles.resizableHandleL,
                }}
            >
                <div className={styles.modalHeader}>
                    <div className={styles.modalTitle}>{title}</div>
                    <div className={styles.modalControls}>
                        <FaWindowMinimize onClick={handleMinimize} onTouchStart={handleMinimize} className={styles.controlBtn} />
                        <FaWindowRestore onClick={handleRestore} onTouchStart={handleRestore} className={styles.controlBtn} />
                        <FaWindowMaximize onClick={handleMaximize} onTouchStart={handleMaximize} className={styles.controlBtn} />
                        {/* <FaExternalLinkAlt onClick={openInNewWindow} onTouchStart={openInNewWindow} className={styles.controlBtn} /> */}
                        <FaTimes onClick={handleClose} onTouchStart={handleClose} className={styles.controlBtn} />
                    </div>
                </div>
                <div className={styles.modalBody}>
                    {children}
                </div>
            </Rnd>
        </>
    );
}

export default DraggableResizableModal;