import { useState } from "react";
import modalState from "../../../state-observables/modal/ModalState";
import styles from "./styles.module.css";


type Props = {

};


export default function ConnectingLoadingSpinnerModal({ }: Props) {

  return (
    <div className={styles.container}>
      This shouldn't take long!
    </div>
  );
}
