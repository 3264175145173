import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Toaster {
    private static lastToastTime: Map<string, number> = new Map();
    private static debounceTime = 5000;

    private static shouldShowToast(message: string): boolean {
        const currentTime = Date.now();
        const lastTimeShown = this.lastToastTime.get(message) || 0;
        const shouldBeShown = currentTime - lastTimeShown > this.debounceTime;
        if (shouldBeShown) {
            this.lastToastTime.set(message, currentTime);
        }
        return shouldBeShown;
    }

    static warn(message: string, options?: ToastOptions) {
        if (this.shouldShowToast(message)) toast.warn(message, options);
    }

    static error(message: string, options?: ToastOptions) {
        if (this.shouldShowToast(message)) toast.error(message, options);
    }

    static info(message: string, options?: ToastOptions) {
        if (this.shouldShowToast(message)) toast.info(message, options);
    }

    static success(message: string, options?: ToastOptions) {
        if (this.shouldShowToast(message)) toast.success(message, options);
    }

    static clearAll() {
        toast.dismiss();
    }

}

export default Toaster;