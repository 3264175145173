import RAFT from "../RAFT";
import RICInterface from "../RAFTInterface";
import { RaftTypeE } from "../../../types/raft";
import { RICLedLcdColours } from "@robotical/roboticaljs";
import { SimplifiedCogStateInfo } from "@robotical/roboticaljs/dist/SystemTypeCog/CogTypes";
import { RaftConnEvent, RaftPublishEvent, RaftUpdateEvent } from "@robotical/raftjs";
import { RaftInfoEvents } from "../../../types/events/raft-info";
import PublishedDataAnalyser, { setButtonThresholdsUtil } from "./PublishedDataAnalyser";
import Logger from "../../../services/logger/Logger";

export class Cog extends RAFT implements RICInterface {
    type = RaftTypeE.COG;
    // Colours to use for LED patterns
    _ledLcdColours: RICLedLcdColours = [
        { led: "#202000", lcd: "#FFFF00" },
        { led: "#880000", lcd: "#FF0000" },
        { led: "#000040", lcd: "#0080FF" },
        { led: "#004000", lcd: "#00FF00" },
    ];

    // RAFT State Info
    public raftStateInfo: SimplifiedCogStateInfo | null = null;

    // Published data analyser  
    public publishedDataAnalyser: PublishedDataAnalyser;

    constructor(
        public id: string,
    ) {
        super(id);
        this.publishedDataAnalyser = new PublishedDataAnalyser(this);
    }

    get ledLcdColours() {
        return this._ledLcdColours;
    }

    /**
    * Gets the RSSI of the RAFT
    */
    getRSSI() {
        // RSSI is not published by the COG yet
        const possibleValues = [126, 127];
        return possibleValues[Math.floor(Math.random() * possibleValues.length)];
    }

    /**
     * Helper function to calculate battery strength based on voltage
     */
    calculateBatteryStrength(voltage: number): number {
        if (voltage >= 2.7) {
            return 100;
        } else if (voltage >= 2.3) {
            return ((voltage - 2.3) / 0.4) * 80 + 20;
        } else if (voltage >= 2.0) {
            return ((voltage - 2.0) / 0.3) * 20;
        } else {
            return 0;
        }
    }

    /**
     * Gets the battery strength of the RAFT
     */
    getBatteryStrength() {
        /* check if usb is connected */
        if (!this.raftStateInfo) return 0.1;
        if (this.raftStateInfo.power.usb === 'yes') return 0; // 0 indicates USB is connected

        /* if usb is not connected, check battery voltage */
        const battV = this.raftStateInfo.power.battV;
        if (!isNaN(+battV)) {
            return Math.round(this.calculateBatteryStrength(+battV));
        }
        return 0;
    }

    /**
   * This methods handles RAFT events coming from the RICConnector of the wrapper
   */
    handleRaftEvent(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        eventData: any
    ) {
        super.handleRaftEvent(eventType, eventEnum, eventName, eventData);
        switch (eventType) {
            case "conn":
                this.connEventHandler(eventEnum as RaftConnEvent, eventName, eventData);
                break;
            case "pub":
                this.pubEventHandler(eventEnum as RaftPublishEvent, eventName, eventData);
                break;
            case "raftinfo":
                this.raftInfoEventHandler(eventEnum as RaftInfoEvents, eventName, eventData);
                break;
            default:
                break;
        }
    }


    /**
     * Conn Event Handler
     */
    async connEventHandler(
        eventEnum: RaftConnEvent,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            case RaftConnEvent.CONN_VERIFIED_CORRECT:
                // set up cog thresholds 
                setButtonThresholdsUtil(this);
                break;
            case RaftConnEvent.CONN_DISCONNECTED:
                // this.publishedDataAnalyser.unsubscribeFromPublishedData();
                break;
            default:
                break;
        }
    }

    /**
     * Pub Event Handler
     */
    async pubEventHandler(
        eventEnum: RaftPublishEvent,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            case RaftPublishEvent.PUBLISH_EVENT_DATA:
                break;
            default:
                break;
        }
    }

    /**
     * Highlights the COG by flashing the LED and playing a tune
     */
    async highlight() {
        await this.sendRestMessage('led/ring/color/#ff8585');
        await this.sendRestMessage('audio/rtttl/NoteASharp:d=4,o=6,b=600:a#');
        await new Promise((resolve) => setTimeout(resolve, 100));
        await this.sendRestMessage('led/ring/color/#000000');
    }

    /**
     * Raft Info Event Handler
     */
    async raftInfoEventHandler(
        eventEnum: RaftInfoEvents,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            case RaftInfoEvents.STATE_INFO:
                this.raftStateInfo = data.stateInfo;
                break;
            default:
                break;
        }
    }

}

export default Cog;