import { createElement, useEffect, useRef, useState } from 'react';
import SensorsDashboard from '@robotical/sensors-dashboard/dist/App';
import { ReactComponent as BetaSignSVG } from "../../../assets/beta-sign.svg";
import modalState from "../../../state-observables/modal/ModalState";
import DetailedFeedbackModal from '../DetailedFeedbackModal';
import styles from './styles.module.css';

function SensorsDashboardModal() {
    const [showBetaSign, setShowBetaSign] = useState(true);
    const betaSignRef = useRef<HTMLDivElement>(null);

    // if (window.mv2Dashboard) {
    //     window.mv2Dashboard.setIsModal(true);
    // }

    const updateBetaSignVisibility = () => {
        if (!betaSignRef.current) {
            return;
        }
        const parent = betaSignRef.current.parentElement;
        if (!parent) {
            return;
        }
        const parentHeight = parent.clientHeight;

        if (parentHeight > 100) {
            setShowBetaSign(true);
        } else {
            setShowBetaSign(false);
        }
    };

    useEffect(() => {
        updateBetaSignVisibility();

        if (!betaSignRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            updateBetaSignVisibility();
        });

        if (!betaSignRef.current.parentElement) {
            return;
        }

        resizeObserver.observe(betaSignRef.current.parentElement);

        return () => {
            resizeObserver.disconnect();
        };
    }, [betaSignRef]);


    return (
        <>
            <div
                style={{ visibility: showBetaSign ? "visible" : "hidden" }}
                className={styles.betaSign}
                onClick={() =>
                    modalState.setModal(createElement(DetailedFeedbackModal), "Anonymous Bug Report")
                }
                ref={betaSignRef}
            >
                <BetaSignSVG />
            </div>

            <SensorsDashboard isInModal={true} />
        </>
    );
}

export default SensorsDashboardModal;