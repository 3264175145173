// import ConnectButton from "../../components/ConnectButton";
// import HelpSection from "../../components/HelpSection";
import styles from "./styles.module.css";
import NavButtons from "../NavButtons";
import NavButton from "../NavButton";
import { FiSettings } from 'react-icons/fi';
import { useConnectedRafts } from "../../../store/SelectedRaftContext";
import ConnectionArea from "../../../components/oneoffs/ConnectionArea";

type Props = {
  isNavMenuMinimized: boolean;
  setIsMinimized: (isMinimized: boolean) => void;
}

export default function NavMenu({ isNavMenuMinimized: isMinimized, setIsMinimized }: Props) {
  const { connectedRafts, setSelectedRaft } = useConnectedRafts();

  const onConfigClick = () => {
    let msg = "Settings not available for testing!";
    const selectedRaft = connectedRafts.find(connectedRaft => connectedRaft.isSelected);
    if (selectedRaft && window.applicationManager.connectedRafts[selectedRaft.id]) {
      msg = "Selected robot version: " + window.applicationManager.connectedRafts[selectedRaft.id].getRaftVersion();
    }
    window.applicationManager.toaster.info(msg);
  }

  return (
    <div className={[styles.navMenuContainer, isMinimized ? styles.minimizedNav : ""].join(" ")}>
      <div className={styles.toggle} onClick={() => setIsMinimized(!isMinimized)}>
        {/* <div className={styles.toggleIcon}>{isMinimized ?
          <BiChevronRight color={"white"} size={30} /> :
          <BiChevronLeft color={"white"} size={30} />
        }</div> */}
      </div>
      <div className={styles.navMenuConnectButtonContainer}>
        {/* {!isMinimized && <Dropdown options={connectedRaftsDropdownOptions} label="Selected Robot" onChange={setSelectedRaft} />} */}
        {/* <ConnectionButton isNavMenuMinimized={isMinimized} /> */}
        <ConnectionArea isNavMenuMinimized={isMinimized} />
      </div>

      <div className={styles.navMenuNavButtonsContainer}>
        <NavButtons isNavMenuMinimized={isMinimized} />
      </div>
      <div className={styles.helpSectionContainer}>
        {/* <NavButton title="" Icon={FiSettings} iconColor="#0a2d2d" onClick={onConfigClick} /> */}
      </div>
    </div>
  );
}
