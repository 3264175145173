import { useNavigate } from "react-router-dom";
import NavLayout from "../../layout/Navigation/NavLayout";
import { createElement, useEffect } from "react";
import EmojiModal from "../../components/modals/EmojiModal";
import { emojiFeedbackModalState } from "../../state-observables/modal/ModalState";
import SensorsDashboard from "@robotical/sensors-dashboard/dist/App";

function SensorsDashboardScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    window.applicationManager.analyticsManager.logEvent("page", { page: "sensors_dashboard" });
  }, []);

  useEffect(() => {
    window.applicationManager.returnToMainApp = () => {
    const shouldExit = window.confirm("Are you sure you want to exit and return to the main app?");
    if (shouldExit) {
      navigate("/home");
    }
  }
  }, []);

  const clickBackHandler = () => {
    navigate("/home");
    const ModalComponent = createElement(EmojiModal, { otherInfoObject: { page: "sensors_dashboard" } });
    emojiFeedbackModalState.setModal(ModalComponent, "");
    emojiFeedbackModalState.updateModalProps({ withCloseButton: true });
  };

  return (
    <NavLayout onClickBack={clickBackHandler}>
      <SensorsDashboard />
    </NavLayout>
  );
}

export default SensorsDashboardScreen;
