import { ConnManager } from "@robotical/roboticaljs";
import randomHashGenerator from "../../utils/helpers/randomHashGenerator";
import CogConnector from "./CogConnector/CogConnector";
import MartyConnector from "./MartyConnector/MartyConnector";
import { RaftConnectionMethod } from "../../types/raft";
import Connector from "./Connector";
import Logger from "../../services/logger/Logger";

const SHOW_LOGS = true;
const TAG = "ConnectorFactory";

export default class ConnectorFactory {
    /**
     * Connect to a RAFT
     *
     * @param {string} method - can be "WebBLE" or "WebSocket"
     * @param {string | object} locator - either a string (WebSocket URL) or an object (WebBLE)
     * @returns Promise<ConnectionAttemptResults>
     *
     */
    static async connectToRaftHelper(method: RaftConnectionMethod, uuids: string[]): Promise<Connector | null> {

        const connManager = new ConnManager();
        Logger.info(SHOW_LOGS, TAG, `Connecting to RAFT with method: ${method}`);
        const wasConnected = await connManager.connect(method, "", uuids);
        Logger.info(SHOW_LOGS, TAG, `Was connected to RAFT: ${wasConnected}`);
        if (!wasConnected) {
            return null;
        }
        const systemInfo = connManager.getConnector().getRaftSystemUtils().getCachedSystemInfo();
        const raftType = systemInfo?.SystemName;
        const connector = this.createConnector(raftType, connManager);
        return connector;
    }

    static createConnector(raftType: string | undefined, connManager: ConnManager): Connector {
        const systemInfo = connManager.getConnector().getRaftSystemUtils().getCachedSystemInfo();
        const serialNo  = systemInfo?.MAC || systemInfo?.SerialNo;
        let connector: Connector;
        const randomId = serialNo || randomHashGenerator();
        switch (raftType) {
            case "RIC":
                connector = new MartyConnector(randomId, connManager);
                break;
            case "Cog":
                connector = new CogConnector(randomId, connManager);
                break;
            case "Axiom":
                throw new Error("Axiom not supported yet");
            default:
                throw new Error("Unknown RAFT type");
        }
        return connector;
    }
}



