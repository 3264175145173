import React from "react";
import { CheckIcon } from "@radix-ui/react-icons";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styles from "./styles.module.css";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { addHoverClass } from "../../../utils/hoverClass";

interface DropdownWithCheckboxesProps {
    items: string[];
    onCheckedChange: (checkedItems: string[]) => void;
}

const DropdownWithCheckboxes: React.FC<DropdownWithCheckboxesProps> = ({ items, onCheckedChange }) => {
    const [checkedItems, setCheckedItems] = React.useState<boolean[]>(items.map(() => true));

    const handleCheckedChange = (index: number, isChecked: boolean) => {
        setCheckedItems(prev => {
            const newCheckedItems = [...prev];
            newCheckedItems[index] = isChecked;
            if (onCheckedChange) {
                const selectedTags = items.filter((_, i) => newCheckedItems[i]);
                onCheckedChange(selectedTags);
            }
            return newCheckedItems;
        });
    };

    const handleSelectAll = (isChecked: boolean) => {
        setCheckedItems(items.map(() => isChecked));
        if (onCheckedChange) {
            const selectedTags = isChecked ? items : [];
            onCheckedChange(selectedTags);
        }
    }

    const handleSelect = (e: Event) => {
        e.preventDefault(); // prevent the dropdown from closing when clicking on a checkbox item
    };

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger className={styles.dropdownTrigger}>
                <span className={styles.filterBySpan}>Filter by</span>
                <CaretDownIcon />
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className={styles.dropdownContent}>
                    <DropdownMenu.CheckboxItem
                        onSelect={handleSelect}
                        checked={checkedItems.every(Boolean)}
                        onCheckedChange={handleSelectAll}
                        className={addHoverClass(styles.checkboxItem,  styles.checkboxItemWithHover)}
                    >
                        <DropdownMenu.ItemIndicator className={styles.itemIndicator}>
                            <CheckIcon />
                        </DropdownMenu.ItemIndicator>
                        <span className={styles.checkboxLabel}>Select All</span>
                    </DropdownMenu.CheckboxItem>
                    <DropdownMenu.Separator className={styles.dropdownSeparator} />
                    {items.map((item, index) => (
                        <DropdownMenu.CheckboxItem
                            key={index}
                            checked={checkedItems[index]}
                            onCheckedChange={(isChecked) => handleCheckedChange(index, isChecked)}
                            onSelect={handleSelect}
                            className={addHoverClass(styles.checkboxItem,  styles.checkboxItemWithHover)}
                        >
                            <DropdownMenu.ItemIndicator className={styles.itemIndicator}>
                                <CheckIcon />
                            </DropdownMenu.ItemIndicator>
                            <span className={styles.checkboxLabel}>{item}</span>
                        </DropdownMenu.CheckboxItem>
                    ))}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default DropdownWithCheckboxes;