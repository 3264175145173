import modalState from "../../../state-observables/modal/ModalState";
import { RED } from "../../../styles/colors";
import SimpleButton from "../../disposables/buttons/SimpleButton";

import { Container } from "./styles";


export default function DisconnectConfirmationModal() {

  return (
    <Container>
      <SimpleButton onClick={() => modalState.closeModal(false)} title="Cancel" />
      <SimpleButton onClick={() => modalState.closeModal(true)} title="Disconnect" colour={RED} />
    </Container>
  );
}