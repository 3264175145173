import Logger from "../../services/logger/Logger";
import { AppSentMessage, IAppSentMessage, IWrapperSentMessage, WRAPPER_RESPONSE_BASE, WrapperSentMessage } from "../../types/communication-between-apps/wrapper-communication";
import randomHashGenerator from "../../utils/helpers/randomHashGenerator";
import isPhoneApp from "../../utils/phone-app-communication/is-phone-app";
import SelfdestructiveMessagePromise from "./SelfdestructiveMessagePromise";

type MessagePromises = { [key: string]: SelfdestructiveMessagePromise | undefined };

const SHOW_LOGS = true;
const TAG = "WrapperAppCommunicator";

export class WrapperCommunicator {
  private messagePromises: MessagePromises = {};
  constructor() {
  }

  /**
   * Receives a message from the wrapper app
   */
  receiveMessage(receivedMessage: IWrapperSentMessage) {
    receivedMessageHandler(receivedMessage.message, receivedMessage.data, receivedMessage.messagePromiseId);
  }

  /**
   * It sends a message to the phone app
   * The client can await the response to that message
   */
  sendMessageAndWait<R>(message: AppSentMessage, data?: any): Promise<R> {
    const messagePromiseId = randomHashGenerator();
    const promise: Promise<R> = new Promise((resolve, reject) => {
      this.messagePromises[messagePromiseId] = new SelfdestructiveMessagePromise(resolve, reject, messagePromiseId, this.messagePromises);
    });

    const messageObject: IAppSentMessage = {
      message,
      data,
      messagePromiseId
    };

    const stringifiedMessage = JSON.stringify(messageObject);

    if (isPhoneApp()) {
      window.ReactNativeWebView.postMessage(stringifiedMessage);
    } else {
      window.WebAppCommunicator.receiveMessage(stringifiedMessage);
    }
    return promise;
  }

  /**
 * It sends a message to the wrapper app
 * The wrapper cannot await the response to that message
 */
  sendMessageNoWait(message: AppSentMessage, data?: any) {
    // Logger.info(SHOW_LOGS, TAG, "Sending message without waiting: " + message);
    const messagePromiseId = randomHashGenerator();
    const messageObject: IAppSentMessage = {
      message,
      data,
      messagePromiseId
    };

    const stringifiedMessage = JSON.stringify(messageObject);
    if (isPhoneApp()) {
      window.ReactNativeWebView.postMessage(stringifiedMessage);
    } else {
      window.WebAppCommunicator.receiveMessage(stringifiedMessage);
    }
  }

  /**
   * Called by the react-native code to respond to sendMessage
   */
  onMessageResponse(response: WRAPPER_RESPONSE_BASE) {
    Logger.info(SHOW_LOGS, TAG, "Received message reply: " + JSON.stringify(response));
    if (this.messagePromises[response.messagePromiseId]) {
      if (response.success) {
        this.messagePromises[response.messagePromiseId]?.resolve(response.results);
      } else {
        this.messagePromises[response.messagePromiseId]?.reject(response.error);
      }
    } else {
      Logger.warn(SHOW_LOGS, TAG, `Unhandled message reply with id: ${response.messagePromiseId}`);
    }
  }
}

export default WrapperCommunicator;

const receivedMessageHandler = async (receivedMessage: WrapperSentMessage, data: any, messagePromiseId: string) => {
  if (data.eventName !== "StateInfo"  && data.eventType !== "pub") {
    Logger.info(SHOW_LOGS, TAG, `RAFT published event: ${JSON.stringify(data)}`);
  }
  switch (receivedMessage) {
    case WrapperSentMessage.RAFT_CONNECTED_RESULTS:
      console.log("RAFT connected results");
      break;
    case WrapperSentMessage.RAFT_PUBLISHED_EVENT:
      window.applicationManager.receivedRICEvent(data.raftId, data.eventType, data.eventEnum, data.eventName, data.eventData);
      break;
    default:
      console.log("Unhandled message");
  }
}