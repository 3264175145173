import styled, { keyframes } from "styled-components";
import { GREY_2 } from "../../../styles/colors";

const comeInFromTop = keyframes`
 0% {
  transform: translateY(-100%);
  }
  80% {
    transform: translateY(20%);
  }

  100%{
    transform: translateY(0);
  }
`;

const leaveToTop = keyframes`
 0% {
   transform: translateY(0);
  }
  20% {
    transform: translateY(20%);
  }
  
  100%{
    transform: translateY(-200%);
  }
`;
export const StyledModalBody = styled.div`
  padding-top: 10px;
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
`;

type StyledModalProps = {
  $closing: boolean;
}

export const StyledModal = styled.div<StyledModalProps>`
  background: ${GREY_2};
  border-radius: 15px;
  padding: 15px;
  animation: ${props => props.$closing ? leaveToTop : comeInFromTop  } .7s ease-in;
  max-height: 90vh;
  overflow: scroll;
  .modal-logo-container {
    display: grid;
    justify-items: center;
    margin-bottom: 1rem;
  }
`;
export const StyledModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
`;

export const StyledTitle = styled.h2`
  font-size: 2.5rem;
  font-family: "BigNoodleTitling Regular";
  text-align: center;
`;
