import SelfdestructiveMessagePromise from "../../application/communicators/SelfdestructiveMessagePromise";
import Logger from "../../services/logger/Logger";
import { AppSentMessage, IAppSentMessage, IWrapperSentMessage, WEB_APP_MESSAGE_BASE, WrapperSentMessage } from "../../types/communication-between-apps/wrapper-communication";
import randomHashGenerator from "../../utils/helpers/randomHashGenerator";
import wrapperAppManager from "../WrapperAppManager";

type MessagePromises = { [key: string]: SelfdestructiveMessagePromise | undefined };

const SHOW_LOGS = true;
const TAG = "WebAppCommunicator";

/**
 * This file is responsible for the communication between the web app and the Wrapper Web App
 */
class WebAppCommunicator {
  private messagePromises: MessagePromises = {};

  constructor() {
  }

  async receiveMessage(receivedMessageStringified: string) {
    Logger.info(SHOW_LOGS, TAG, "Received message: " + receivedMessageStringified);
    const receivedMessage: IAppSentMessage = JSON.parse(receivedMessageStringified);
    receivedMessageHandler(receivedMessage.message, receivedMessage.data, receivedMessage.messagePromiseId);
  }

  /**
   * It sends a message to the phone app
   * The client can await the response to that message
   */
  sendMessageAndWait<R>(message: WrapperSentMessage, data?: any): Promise<R> {
    Logger.info(SHOW_LOGS, TAG, "Sending message with waiting: " + message);
    const messagePromiseId = randomHashGenerator();
    const promise: Promise<R> = new Promise((resolve, reject) => {
      // this.messagePromises[messagePromiseId] = { resolve: (value: R | PromiseLike<R>) => resolve(value), reject };
      this.messagePromises[messagePromiseId] = new SelfdestructiveMessagePromise(resolve, reject, messagePromiseId, this.messagePromises);
    });

    const messageObject: IWrapperSentMessage = {
      message,
      data,
      messagePromiseId
    };

    window.wrapperCommunicator.receiveMessage(messageObject);
    return promise;
  }

  /**
   * It sends a message to the phone app
   * The client cannot await the response to that message
   */
  sendMessageNoWait(message: WrapperSentMessage, data?: any) {
    // Logger.info(SHOW_LOGS, TAG, "Sending message without waiting: " + message);
    const messagePromiseId = randomHashGenerator();
    const messageObject: IWrapperSentMessage = {
      message,
      data,
      messagePromiseId
    };

    window.wrapperCommunicator.receiveMessage(messageObject);
  }

  /**
   * Called by the react-native code to respond to sendMessage
   */
  onMessageResponse(response: WEB_APP_MESSAGE_BASE) {
    Logger.info(SHOW_LOGS, TAG, "Received message reply: " + JSON.stringify(response));
    if (this.messagePromises[response.messagePromiseId]) {
      if (response.success) {
        this.messagePromises[response.messagePromiseId]?.resolve(response.results);
      } else {
        this.messagePromises[response.messagePromiseId]?.reject(response.error);
      }
    } else {
      Logger.warn(SHOW_LOGS, TAG, `Unhandled message reply with id: ${response.messagePromiseId}`);
    }
  }
}

export default WebAppCommunicator;

const receivedMessageHandler = async (receivedMessage: AppSentMessage, data: any, messagePromiseId: string) => {
  // console.debug("Received message from web app", receivedMessage, data);
  switch (receivedMessage) {
    case AppSentMessage.RAFT_IS_CONNECTED:
      try {
        console.log("3")
        // const connectionResults = await wrapperAppManager.isConnected();
        console.log("12")
        // console.log("raft is connected results", connectionResults);
        window.wrapperCommunicator.onMessageResponse({ success: true, error: "", results: { log: "13" }, messagePromiseId });
      } catch (error: any) {
        window.wrapperCommunicator.onMessageResponse({ success: false, error: error.message, messagePromiseId });
      }
      break;
    case AppSentMessage.RAFT_CONNECT:
      try {
        const connectionResults = await wrapperAppManager.connect(data.method, data.uuids);
        window.wrapperCommunicator.onMessageResponse({ success: true, error: "", results: connectionResults, messagePromiseId });
      } catch (error: any) {
        window.wrapperCommunicator.onMessageResponse({ success: false, error: error.message, messagePromiseId });
      }
      break;
    case AppSentMessage.RAFT_GET_NAME:
      try {
        const ricName = await wrapperAppManager.getRaftName(data.raftId);
        window.wrapperCommunicator.onMessageResponse({ success: true, error: "", results: ricName, messagePromiseId });
      } catch (error: any) {
        window.wrapperCommunicator.onMessageResponse({ success: false, error: error.message, messagePromiseId });
      }
      break;
    case AppSentMessage.RAFT_VERIFY:
      try {
        const verifyResults = await wrapperAppManager.verifyRaft(data.ledLcdColours, data.raftId);
        window.wrapperCommunicator.onMessageResponse({ success: true, error: "", results: verifyResults, messagePromiseId });
      } catch (error: any) {
        window.wrapperCommunicator.onMessageResponse({ success: false, error: error.message, messagePromiseId });
      }
      break;
    case AppSentMessage.RAFT_STOP_VERIFY:
      try {
        const stopVerifyResults = await wrapperAppManager.stopVerifyingRaft(data.isCorrectRIC, data.raftId);
        window.wrapperCommunicator.onMessageResponse({ success: true, error: "", results: stopVerifyResults, messagePromiseId });
      } catch (error: any) {
        window.wrapperCommunicator.onMessageResponse({ success: false, error: error.message, messagePromiseId });
      }
      break;
    case AppSentMessage.RAFT_DISCONNECT:
      try {
        const disconnectResults = await wrapperAppManager.disconnect(data.raftId);
        window.wrapperCommunicator.onMessageResponse({ success: true, error: "", results: disconnectResults, messagePromiseId });
      } catch (error: any) {
        window.wrapperCommunicator.onMessageResponse({ success: false, error: error.message, messagePromiseId });
      }
      break;
    case AppSentMessage.RAFT_SEND_REST:
      try {
        const restResults = await wrapperAppManager.sendRestMessage(data.msg, data.params, data.raftId);
        window.wrapperCommunicator.onMessageResponse({ success: true, error: "", results: restResults, messagePromiseId });
      } catch (error: any) {
        window.wrapperCommunicator.onMessageResponse({ success: false, error: error.message, messagePromiseId });
      }
      break;
    case AppSentMessage.GET_MISSED_CONN_EVENT:
      try {
        const missedConnEventResults = wrapperAppManager.getMissedConnEvent(data.raftId);
        window.wrapperCommunicator.onMessageResponse({ success: true, error: "", results: missedConnEventResults, messagePromiseId });
      } catch (error: any) {
        window.wrapperCommunicator.onMessageResponse({ success: false, error: error.message, messagePromiseId });
      }
      break;
    case AppSentMessage.RAFT_STREAM_AUDIO:
      try {
        const streamAudioResults = wrapperAppManager.streamAudio(data.raftId, data.streamContents, data.clearExisting, data.duration);
        window.wrapperCommunicator.onMessageResponse({ success: true, error: "", results: streamAudioResults, messagePromiseId });
      } catch (error: any) {
        window.wrapperCommunicator.onMessageResponse({ success: false, error: error.message, messagePromiseId });
      }
      break;
    default:
      Logger.warn(SHOW_LOGS, TAG, `Unhandled message: ${receivedMessage}`);
  }
}