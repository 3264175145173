import ProjectsGrid from "../../components/disposables/Grid";
import { GridItemProps } from "../../components/disposables/Grid/GridItem";
import Layout from "../../layout/Layout";
import { RaftTypeE } from "../../types/raft";
import styles from "./styles.module.css";
import BlocksJrIcon from "../../assets/nav-buttons/marty-blocksjr.svg";
import BlocksIcon from "../../assets/nav-buttons/marty-blocks.svg";
import MartyUnpluggedIcon from "../../assets/nav-buttons/marty-unplugged.svg";
import ControllerIcon from "../../assets/nav-buttons/controller.svg";
import SensorsDashboardIcon from "../../assets/nav-buttons/sensors-dashboard.svg";
import { useEffect } from "react";

const imageStyle: React.CSSProperties = {
  width: "80%",
  height: "90%",
  alignSelf: "center",
  justifySelf: "center",
};

const imageX = "10%";
const imageY = "5%";


const dummyGridContent: GridItemProps[] = [
  {
    type: "platform", title: "Blocks Jr", subtitle: "", href: "/blocks-jr", contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    SvgIcon: () => <image x={imageX} y={imageY} href={BlocksJrIcon} style={imageStyle} />,
    // SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
  },
  {
    type: "platform", title: "Blocks", subtitle: "", href: "/blocks", contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    SvgIcon: () => <image x={imageX} y={imageY} href={BlocksIcon} style={imageStyle} />,
    // SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
  },
  // {
  //   type: "platform", title: "Marty Unplugged", subtitle: "", href: "/marty-unplugged", contentTags: [RaftTypeE.MARTY],
  //   SvgIcon: () => <image x={imageX} y={imageY} href={MartyUnpluggedIcon} style={imageStyle} />,
  //   // SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
  // },
  // {
  //   type: "platform", title: "Controller", subtitle: "", href: "/controller", contentTags: [RaftTypeE.MARTY],
  //   SvgIcon: () => <image x={imageX} y={imageY} href={ControllerIcon} style={imageStyle} />,
  //   // SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
  // },
  {
    type: "platform",
    title: "Marty Controller", subtitle: "", contentTags: [RaftTypeE.MARTY], href: '/marty-controller',
    SvgIcon: () => <image x={imageX} y={imageY} href={ControllerIcon} style={imageStyle} />,
  },

  {
    type: "platform",
    title: "Sensors Dashboard", subtitle: "", contentTags: [RaftTypeE.MARTY, RaftTypeE.COG], href: '/sensors-dashboard',
    SvgIcon: () => <image x={imageX} y={imageY} href={SensorsDashboardIcon} style={imageStyle} />,
  },
];

function HomeScreen() {
  useEffect(() => {
    window.applicationManager.analyticsManager.logEvent("page", { page: "home" });
  }, []);

  useEffect(() => {
    window.applicationManager.analyticsManager.logEvent("page", { page: "home" });
  }, []);

  return (
    <Layout>
      <div className={styles.projectsSectionContainer}>
        <ProjectsGrid projects={dummyGridContent} />
      </div>
    </Layout>
  );
}

export default HomeScreen;
