/**
 * Only showing the modal once in the lifetime of the app
 */
import { createContext, useContext, useRef, useState } from 'react';

type EmojiModalDisplayContextType = {
  hasDisplayed: React.MutableRefObject<boolean>
  setHasDisplayed: (value: boolean) => void;
};

const EmojiModalDisplayContext = createContext<EmojiModalDisplayContextType>({
  hasDisplayed: { current: false },
  setHasDisplayed: () => {},
});

export const EmojiModalDisplayProvider = ({ children }: { children: React.ReactNode }) => {
    const hasDisplayed = useRef(false);

    const setHasDisplayed = (value: boolean) => {
        console.log("setHasDisplayed", value);
        hasDisplayed.current = value;
    }

  return (
    <EmojiModalDisplayContext.Provider value={{ hasDisplayed, setHasDisplayed }}>
      {children}
    </EmojiModalDisplayContext.Provider>
  );
};

export const useEmojiModalDisplay = () => useContext(EmojiModalDisplayContext);
