import { createContext, useContext, useEffect, useState } from 'react';
import { RaftTypeE } from '../types/raft';

export enum NewRobotIdE {
  NEW = "=+__new__+=",
}

export type ConnectedRaftItem = {
  id: string;
  name: string;
  type: RaftTypeE;
  isSelected: boolean;
}

const ConnectedRaftContext = createContext({
  connectedRafts: [] as ConnectedRaftItem[],
  addConnectedRaft: (connectedRaft: ConnectedRaftItem) => { },
  removeConnectedRaft: (connectedRaftId: string) => { },
  setSelectedRaft: (connectedRaftId: string) => { },
});

// Provider component
export const ConnectedRaftContentProvider = ({ children }: { children: React.ReactNode }) => {
  const [connectedRafts, setConnectedRafts] = useState([{ id: NewRobotIdE.NEW, isSelected: true, name: "Connect to new robot", type: RaftTypeE.undefined }] as ConnectedRaftItem[]);

  useEffect(() => {
    if (window.applicationManager) {
      window.applicationManager.connectedRaftContextMethods = {
        addConnectedRaft: (connectedRaft: ConnectedRaftItem) => {
          addConnectedRaft(connectedRaft);
        },
        removeConnectedRaft: (connectedRaftId: string) => {
          removeConnectedRaft(connectedRaftId);
        },
        setSelectedRaft: (connectedRaftId: string) => {
          setSelectedRaft(connectedRaftId);
        },
      };
    }
  }, []);


  useEffect(() => {
    window.applicationManager.connectedRaftsContext = connectedRafts;
  }, [connectedRafts]);

  const addConnectedRaft = (connectedRaft: ConnectedRaftItem) => {
    // if the new raft is already in the list, do not add it again, just select it
    const existingRaft = connectedRafts.find((connectedRaft_) => connectedRaft_.id === connectedRaft.id);
    if (existingRaft) {
      setConnectedRafts((prev) => prev.map((connectedRaft_) => ({
        ...connectedRaft_,
        isSelected: connectedRaft_.id === connectedRaft.id,
      })));
      return;
    }
    // if the newly added raft is selected, deselect the previously selected raft
    if (connectedRaft.isSelected) {
      setConnectedRafts((prev) => prev.map((connectedRaft) => ({ ...connectedRaft, isSelected: false })));
    }
    setConnectedRafts((prev) => [...prev, connectedRaft]);
  };

  const removeConnectedRaft = (connectedRaftId: string) => {
    // if the removed raft is selected, select the first raft in the list
    const selectedRaft = connectedRafts.find((connectedRaft) => connectedRaft.isSelected);
    if (selectedRaft?.id === connectedRaftId) {
      setConnectedRafts((prev) => prev.map((connectedRaft, index) => ({
        ...connectedRaft,
        isSelected: index === 0,
      })));
    }
    setConnectedRafts((prev) => prev.filter((connectedRaft) => connectedRaft.id !== connectedRaftId));
  };

  const setSelectedRaft = (connectedRaftId: string) => {
    // deselect the previously selected raft
    setConnectedRafts((prev) => prev.map((connectedRaft) => ({
      ...connectedRaft,
      isSelected: false,
    })));
    setConnectedRafts((prev) => prev.map((connectedRaft) => ({
      ...connectedRaft,
      isSelected: connectedRaft.id === connectedRaftId,
    })));
  };

  return (
    <ConnectedRaftContext.Provider value={{ connectedRafts, addConnectedRaft, removeConnectedRaft, setSelectedRaft }}>
      {children}
    </ConnectedRaftContext.Provider>
  );
};

export const useConnectedRafts = () => useContext(ConnectedRaftContext);