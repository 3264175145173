import styles from "./styles.module.css";
import { createElement, SyntheticEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavLayout from "../../layout/Navigation/NavLayout";
import EmojiModal from "../../components/modals/EmojiModal";
import { emojiFeedbackModalState } from "../../state-observables/modal/ModalState";
import { useLocation } from "react-router-dom";
import LoadingSpinnerMarty from "../../components/disposables/LoadingSpinnerMarty";

function BlocksJR() {
  const [isBlocksJRLoaded, setIsBlocksJRLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const handleBlocksJRLoad = (e: SyntheticEvent<HTMLDivElement>) => {
    setIsBlocksJRLoaded((oldState) => oldState + 1);
    setIsLoading(false); // Set loading to false when blocks are loaded
    // focus on the object
    const object = e.target as HTMLObjectElement;
    object.contentDocument?.body.focus();
    object.contentWindow?.focus();

    // planting the application manager object in the window object
    const blocksJrWindow = object.contentWindow;
    if (blocksJrWindow) {
      blocksJrWindow.applicationManager = window.applicationManager;
    }
  };


  useEffect(() => {
    window.applicationManager.returnToMainApp = () => {
      const shouldExit = window.confirm("Are you sure you want to exit and return to the main app?");
      if (shouldExit) {
        navigate("/");
      }
    }
  }, [isBlocksJRLoaded]);

  useEffect(() => {
    window.applicationManager.analyticsManager.logEvent("page", { page: "blocksjr" });
  }, []);

  const clickBackHandler = () => {
    navigate("/");
    const ModalComponent = createElement(EmojiModal, { otherInfoObject: { page: "blocksjr" } });
    emojiFeedbackModalState.setModal(ModalComponent, "");
    emojiFeedbackModalState.updateModalProps({ withCloseButton: true });
  };

  return (
    <NavLayout onClickBack={clickBackHandler}>
      <main className={styles.mainWindow}>
        <div className={styles.contentWindow}>
          {isLoading && <LoadingSpinnerMarty />}
          <object
            type="text/html"
            data={`./MartyBlocksJR/index.html?${params.toString()}`}
            onLoad={handleBlocksJRLoad}
            style={{ width: "100%", height: "100%" }}
            id="blocksJrObject"
          ></object>
          {/* <object type="text/html" data="http://localhost:8601/index.html" onLoad={handleBlocksJRLoad} style={{width:'100%', height:'100%'}}>
        </object> */}
        </div>
      </main>
    </NavLayout>
  );
}

export default BlocksJR;
