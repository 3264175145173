import RAFT from "../RAFT";
import { raftPubSubscriptionHelper } from "../raft-subscription-helpers";
import { isVersionGreater_errorCatching } from "../../../utils/helpers/compare-version";
import EventEmitter from "events";
import Cog from "./Cog";
import Logger from "../../../services/logger/Logger";
import { SimplifiedCogStateInfo } from "@robotical/roboticaljs/dist/SystemTypeCog/CogTypes";

const SHOW_LOGS = true;
const TAG = "PublishedDataAnalyser";
interface CogState {
    tilt: "none" | "forward" | "backward" | "left" | "right";
    movementType: "none" | "shake" | "move";
    rotation: "none" | "clockwise" | "counterClockwise";
    buttonClick: "none" | "click" | "release";
    objectSense: "none" | "left" | "right";
    lightSense: "none" | "high" | "mid" | "low";
}

class PublishedDataAnalyser extends EventEmitter {
    public cogState: CogState;
    private pubSub: ReturnType<typeof raftPubSubscriptionHelper> | null;

    rotationDetection = new RotationDetection();
    shakeDetector = new ShakeDetector();
    buttonClickDetection = new ButtonClickDetection();
    tiltDetection = new TiltDetection();
    objectSenseDetection = new ObjectSenseDetection();
    lightSenseDetection = new LightSenseDetection();

    public eventsMap: {
        tilt: { [key in CogState["tilt"]]: string },
        movementType: { [key in CogState["movementType"]]: string },
        rotation: { [key in CogState["rotation"]]: string },
        buttonClick: { [key in CogState["buttonClick"]]: string },
        objectSense: { [key in CogState["objectSense"]]: string },
        lightSense: { [key in CogState["lightSense"]]: string },
    } = {
            tilt: {
                forward: "tiltForward",
                backward: "tiltBackward",
                left: "tiltLeft",
                right: "tiltRight",
                none: "noTilt"
            },
            movementType: {
                shake: "shake",
                move: "move",
                none: "noMovement"
            },
            rotation: {
                clockwise: "rotationClockwise",
                counterClockwise: "rotationCounterClockwise",
                none: "noRotation"
            },
            buttonClick: {
                click: "buttonClick",
                release: "buttonRelease",
                none: "noButtonClick"
            },
            objectSense: {
                left: "objectSenseLeft",
                right: "objectSenseRight",
                none: "noObjectSense"
            },
            lightSense: {
                high: "highLightSense",
                mid: "midLightSense",
                low: "lowLightSense",
                none: "noLightSense"
            },
        }

    TiltDetection = TiltDetection;
    constructor(
        private cog: RAFT
    ) {
        super();
        this.cog = cog;
        this.cogState = {
            tilt: "none",
            movementType: "none",
            rotation: "none",
            buttonClick: "none",
            objectSense: "none",
            lightSense: "none",
        };
        this.pubSub = null;
        this.subscribeToPublishedData();
    }

    subscribeToPublishedData() {
        this.pubSub = raftPubSubscriptionHelper(this.cog);
        this.pubSub.subscribe((data) => {
            this.analyse(data.stateInfo);
        });
    }

    unsubscribeFromPublishedData() {
        this.pubSub?.unsubscribe();
    }

    analyse(data: SimplifiedCogStateInfo) {
        if (!data) return;
        const accelData = data.accelerometer;
        const gyroData = data.gyroscope;
        const lightData = data.light;
        let isMoving;
        if (accelData) isMoving = this.shakeDetector.detectShake(accelData.ax, accelData.ay, accelData.az, Date.now(), this);
        accelData && this.tiltDetection.detectTilt(accelData.ax, accelData.ay, accelData.az, isMoving, this, this.cog.getRaftVersion())
        gyroData && this.rotationDetection.detectRotation(gyroData.gz, isMoving, this);
        lightData && this.buttonClickDetection.detectButtonClick(lightData.ir2, this, this.cog.getRaftVersion())
        lightData && this.objectSenseDetection.detectObjectSense([lightData.ir0, lightData.ir1], this);
        lightData && this.lightSenseDetection.detectLightSense(lightData.amb0, this);
    }

    setTilt(tilt: CogState["tilt"]) {
        this.cogState.tilt = tilt;
        this.emit(this.eventsMap.tilt[tilt]);
    }

    setMovementType(movementType: CogState["movementType"]) {
        this.cogState.movementType = movementType;
        this.emit(this.eventsMap.movementType[movementType]);
    }

    setRotation(rotation: CogState["rotation"]) {
        this.cogState.rotation = rotation;
        this.emit(this.eventsMap.rotation[rotation]);
    }

    setButtonClick(buttonClick: CogState["buttonClick"]) {
        this.cogState.buttonClick = buttonClick;
        this.emit(this.eventsMap.buttonClick[buttonClick]);
    }

    setObjectSense(objectSense: CogState["objectSense"]) {
        this.cogState.objectSense = objectSense;
        this.emit(this.eventsMap.objectSense[objectSense]);
    }

    setLightSense(lightSense: CogState["lightSense"]) {
        this.cogState.lightSense = lightSense;
        this.emit(this.eventsMap.lightSense[lightSense]);
    }
}

class TiltDetection {
    distance(a: number, b: number) { return Math.sqrt((Math.pow(a, 2) + Math.pow(b, 2))) }

    static rotateAccelData(x: number, y: number, z: number, degrees: number) {
        // Convert degrees to radians
        const radians = degrees * (Math.PI / 180);

        // First rotate by 180 degrees about y axis
        let rotatedX = 0 - x;
        let rotatedY = y;
        let rotatedZ = 0 - z;

        const initialRotatedX = rotatedX;

        // Calculate cosine and sine of the rotation angle
        const cosTheta = Math.cos(radians);
        const sinTheta = Math.sin(radians);

        // Rotate around the z-axis
        rotatedX = initialRotatedX * cosTheta - rotatedY * sinTheta;
        rotatedY = initialRotatedX * sinTheta + rotatedY * cosTheta;
        rotatedZ = rotatedZ;  // z remains unchanged as the rotation is around the z-axis

        return { x: rotatedX, y: rotatedY, z: rotatedZ };
    }

    detectTilt(ax: number, ay: number, az: number, isMoving = false, analyser: PublishedDataAnalyser, cogVersion: string) {
        if (isMoving) return;

        const tiltCorrectionForOlderCog = 30;
        const tiltCorrectionForNewerCog = -90;
        const correctionCutOffVersion = "1.2.0";
        let tiltCorrection = tiltCorrectionForOlderCog;

        if (isVersionGreater_errorCatching(cogVersion, correctionCutOffVersion)) {
            tiltCorrection = tiltCorrectionForNewerCog;
        }

        const { x, y, z } = TiltDetection.rotateAccelData(ax, ay, az * -1, tiltCorrection);
        const pitch = Math.atan2(x, this.distance(y, z));
        const roll = Math.atan2(y, this.distance(x, z));
        const yaw = Math.atan2(z, this.distance(x, y));
        // no tilt example values: pitch: 0.00, roll: 0.00, yaw: 1.50
        // tilt left example values: pitch: 0.00, roll: -1.00, yaw: 0.50
        // tilt right example values: pitch: 0.00, roll: 1.00, yaw: 0.50
        // tilt forward example values: pitch: -1.00, roll: 0.00, yaw: 0.50
        // tilt backward example values: pitch: 1.00, roll: 0.00, yaw: 0.50

        const forwardBackwardThreshold = 20 * (Math.PI / 180); // threshold for forward and backward tilt
        const leftRightThreshold = 20 * (Math.PI / 180); // threshold for left and right tilt
        const upDownThreshold = 0.5; // threshold for up and down tilt
        let tiltDirection: CogState["tilt"] = "none";
        if (pitch < -forwardBackwardThreshold) {// && Math.abs(yaw) < upDownThreshold) {
            tiltDirection = "forward";
        }
        if (pitch > forwardBackwardThreshold) {// && Math.abs(yaw) < upDownThreshold) {
            tiltDirection = "backward";
        }
        if (roll < -leftRightThreshold) {// && Math.abs(yaw) < upDownThreshold) {
            tiltDirection = "left";
        }
        if (roll > leftRightThreshold) {// && Math.abs(yaw) < upDownThreshold) {
            tiltDirection = "right";
        }
        analyser.setTilt(tiltDirection);
    }
}

class RotationDetection {
    private dataBuffer: number[] = [];
    private bufferSize = 20; // buffer size for rotation detection
    private DELAY_FOR_ROTATION = 500; // delay between rotation detection
    private ROTATION_THRESHOLD = 8; // threshold for rotation detection
    private rotationDetected = false;
    private lastRotationDetectionTime = 0;
    private rotationTimer = null;

    constructor() {
    }

    addToBuffer(data: number) {
        this.dataBuffer.push(data);
        if (this.dataBuffer.length > this.bufferSize) {
            this.dataBuffer.shift();
        }
    }

    detectRotation(
        gz: number,
        isMoving = false,
        analyser: PublishedDataAnalyser
    ) {
        const currentTime = Date.now();

        this.addToBuffer(gz);
        if (this.dataBuffer.length < this.bufferSize) {
            return;  // Wait until buffer is full
        }

        if (currentTime - this.lastRotationDetectionTime < this.DELAY_FOR_ROTATION || isMoving) {
            // Ensure there is a minimum time between detections
            return;
        }

        const metric = this.calculateMetric();
        // Check if the magnitude of the rate of change is above the threshold
        if (metric > this.ROTATION_THRESHOLD || metric < -this.ROTATION_THRESHOLD) {
            this.lastRotationDetectionTime = currentTime;
            this.dataBuffer = [];
            console.log("Rotation detected. Rotation: ", metric > this.ROTATION_THRESHOLD ? "clockwise" : "counter-clockwise");
            if (metric > this.ROTATION_THRESHOLD) {
                // console.log("Clockwise rotation detected:", metric);
                analyser.setRotation("clockwise");
            } else if (metric < -this.ROTATION_THRESHOLD) {
                // console.log("Counter-clockwise rotation detected:", metric);
                analyser.setRotation("counterClockwise");
            }
        } else {
            analyser.setRotation("none");
        }
    }

    calculateMetric() {
        //let gzArray = [];
        let sum = 0;
        for (let i = 0; i < this.dataBuffer.length; i++) {
            //sum += this.dataBuffer[i].LSM6DS.gz;
            sum += this.dataBuffer[i];
            //gzArray.push(this.dataBuffer[i]);
        }
        //console.log("gz buffer (" + gzArray.length + " elements avg. " + (sum / this.dataBuffer.length) + "): " + gzArray);
        //console.log(this.dataBuffer);
        return sum / this.dataBuffer.length;
    }
}

class ShakeDetector {
    private thresholdAccelerationMove = 0.3;
    private thresholdAcceleration = 1; // how much acceleration is needed to consider shaking
    private thresholdShakeNumber = 1; // how many shakes are needed
    private interval = 400; // how much time between shakes
    private maxShakeDuration = 1500; // Maximum duration between first and last shakes in a sequence
    private coolOffPeriod = 1500; // how much time to wait before detecting another shake
    private lastTime = 0;
    private lastTimeShakeDetected = 0;
    private sensorBundles: { x: number, y: number, z: number, timestamp: number }[] = [];
    private gravityVector = [0, 0, 0];
    private lastVector = [0, 0, 0];
    private shakeInProgress = false;
    private moveInProgress = false;

    constructor() {
    }

    detectShake(xAcc: number, yAcc: number, zAcc: number, timestamp: number, analyser: PublishedDataAnalyser) {
        this.thresholdAcceleration = this.thresholdAcceleration;
        this.thresholdAccelerationMove = this.thresholdAccelerationMove;
        this.thresholdShakeNumber = this.thresholdShakeNumber;
        this.interval = this.interval;
        this.maxShakeDuration = this.maxShakeDuration;
        this.coolOffPeriod = this.coolOffPeriod;

        const magAcc = Math.sqrt(xAcc * xAcc + yAcc * yAcc + zAcc * zAcc);
        if (magAcc > 0.9 && magAcc < 1.1) {
            // device is stationary-ish, log direction of acc values to get a rough reading on where down is
            this.gravityVector = [xAcc, yAcc, zAcc];
            if (this.moveInProgress) {
                // console.log("move detected");
                // analyser.setMovementType("move");
            } else {
                // console.log("no move detected");
                analyser.setMovementType("none");
            }
            this.moveInProgress = false;
            this.shakeInProgress = false;
            this.sensorBundles = [];
            return this.shakeInProgress;
        } else {
            //console.log("move in progrss. prev state: ", this.moveInProgress);
            // potentially threshold this with thresholeAccelerationMove if we want it to be less trigger happy
            this.moveInProgress = true;

            // this assumes that the orientation of the device doesn't change during the movement, so it's not ideal
            const x = xAcc - this.gravityVector[0];
            const y = yAcc - this.gravityVector[1];
            const z = zAcc - this.gravityVector[2];
            const mag = Math.sqrt(x * x + y * y + z * z);

            if (mag > this.thresholdAcceleration || this.shakeInProgress) {
                this.shakeInProgress = true;
                const diffThresh = this.thresholdAcceleration;
                if (mag > this.thresholdAcceleration) {
                    // console.log('large magnitude movement ', x, y, z, this.gravityVector);
                    // check if the acc vector is significantly changed from the previous large value
                    if (!this.sensorBundles.length || Math.sqrt(Math.pow(this.lastVector[0] - x, 2) + Math.pow(this.lastVector[1] - y, 2) + Math.pow(this.lastVector[2] - z, 2)) > this.thresholdAcceleration) {
                        this.sensorBundles.push({ x, y, z, timestamp });
                        //console.log(this.sensorBundles);
                        this.lastVector = [x, y, z];
                        // todo - call performCheck() to do a more detailed analysis of the readings? Might need some tweaks
                        if (this.sensorBundles.length > this.thresholdShakeNumber) {
                            // console.log("Shake detected!");
                            this.sensorBundles = [];
                            this.shakeInProgress = true;
                            analyser.setMovementType("shake");
                        }
                    }
                    // this.noMoveCallback();
                } else {
                    if (!this.sensorBundles.length || (timestamp - this.sensorBundles[this.sensorBundles.length - 1].timestamp) > this.interval) {
                        this.shakeInProgress = false;
                        this.sensorBundles = [];
                        // console.log("resetting shake detector. Move detected");
                        // fire move detector
                        analyser.setMovementType("none");
                    }
                }
            }

            return this.shakeInProgress;
            /*
            if (this.sensorBundles.length === 0 || timestamp - this.lastTime > this.interval) {
                // Check if we should reset based on time since last recorded shake
                if (this.sensorBundles.length > 0 && (timestamp - this.sensorBundles[0].timestamp) > this.maxShakeDuration) {
                    this.sensorBundles = []; // Reset the sensor data if the shakes are too far apart
                }
                this.sensorBundles.push({ xAcc, yAcc, zAcc, timestamp });
                this.lastTime = timestamp;
                this.performCheck();
            }
            */
        }
    }

    performCheck(analyser: PublishedDataAnalyser) {
        const matrix = [
            [0, 0], // X axis positive and negative
            [0, 0], // Y axis positive and negative
            [0, 0]  // Z axis positive and negative
        ];

        for (const bundle of this.sensorBundles) {
            this.updateAxis(0, bundle.x, matrix);
            this.updateAxis(1, bundle.y, matrix);
            this.updateAxis(2, bundle.z, matrix, -1);
        }

        // check if any of the negatives and the positives are greater than the threshold
        const negativesTotal = matrix.reduce((acc, axis) => acc + axis[1], 0);
        const positivesTotal = matrix.reduce((acc, axis) => acc + axis[0], 0);

        if (matrix.some(axis => axis[0] >= this.thresholdShakeNumber && axis[1] >= this.thresholdShakeNumber)) {
            // if (positivesTotal >= this.thresholdShakeNumber && negativesTotal >= this.thresholdShakeNumber) {

            if (Date.now() - this.lastTimeShakeDetected < this.coolOffPeriod) {
                return;
            }
            this.lastTimeShakeDetected = Date.now();

            // console.log("Shake detected!", JSON.stringify(matrix));
            analyser.setMovementType("shake");
            this.sensorBundles = [];
        }
    }

    updateAxis(index: number, acceleration: number, matrix: number[][], adjustment = 0) {
        const accelerationAdjusted = acceleration + adjustment;
        if (accelerationAdjusted > this.thresholdAcceleration) {
            matrix[index][0]++;
            // console.log(JSON.stringify(matrix));
        } else if (accelerationAdjusted < -this.thresholdAcceleration) {
            matrix[index][1]++;
            // console.log(JSON.stringify(matrix));
        }
    }
}

export class ButtonClickDetection {
    /* 
    When the threshold is exceeded, the button is clicked, but we want to send the event when the button is released 
    so that the event is triggered only once. 
    */
    clickThreshold = 1600;
    releaseThreshold = 1590;
    lastTime = 0;
    buttonClicked = false;
    constructor() {
    }

    detectButtonClick(buttonValue: number, analyser: PublishedDataAnalyser, cogVersion: string) {
        const currentTime = Date.now();
        if (buttonValue > this.clickThreshold && !this.buttonClicked) {
            // console.log("Button clicked", buttonValue);
            this.buttonClicked = true;
            this.lastTime = currentTime;
            Logger.info(SHOW_LOGS, TAG, "Button clicked");
            analyser.setButtonClick("click");
        } else if (buttonValue < this.releaseThreshold && this.buttonClicked) {
            // console.log("Button released", buttonValue);
            this.buttonClicked = false;
            Logger.info(SHOW_LOGS, TAG, "Button released");
            analyser.setButtonClick("release");
        }
        // } else {
        //     this.buttonClicked = false;
        //     this.buttonReleaseCallback();
        // }
    }

}
class ObjectSenseDetection {
    private objectSensed0Threshold = 2500; // left of the arrow
    private objectSensed1Threshold = 2500; // right of the arrow
    private objectSensed2Threshold = 1500; // button
    constructor() {
    }

    detectObjectSense(objectSenseValue: number[], analyser: PublishedDataAnalyser) {

        if (objectSenseValue[0] > this.objectSensed0Threshold) {
            analyser.setObjectSense("left");
        } else if (objectSenseValue[1] > this.objectSensed1Threshold) {
            analyser.setObjectSense("right");
        } else {
            analyser.setObjectSense("none");
        }
    }
}

class LightSenseDetection {
    private lowLightThreshold = 5;
    private midLightThreshold = 250;
    private highLightThreshold = 450;
    constructor() {
    }

    detectLightSense(lightSenseValue: number, analyser: PublishedDataAnalyser) {
        if (lightSenseValue > this.highLightThreshold) {
            analyser.setLightSense("high");
        } else if (lightSenseValue > this.midLightThreshold) {
            analyser.setLightSense("mid");
        } else if (lightSenseValue > this.lowLightThreshold) {
            analyser.setLightSense("low");
        } else {
            analyser.setLightSense("none");
        }
    }
}

export const setButtonThresholdsUtil = async (connectedCog: Cog) => {
    Logger.info(SHOW_LOGS, TAG, "Setting button thresholds");
    try {
        const lightResponse = await connectedCog.sendRestMessage('light') as { rslt: string, light: { irMin4: number, irMax4: number } };
        if (lightResponse && lightResponse.rslt === "ok" && lightResponse.light.irMax4) {
            const irMin = lightResponse.light.irMin4;
            const irMax = lightResponse.light.irMax4;
            Logger.info(SHOW_LOGS, TAG, `Got button thresholds: irMin: ${irMin}, irMax: ${irMax}`);
            const buttonClickDetection = connectedCog.publishedDataAnalyser.buttonClickDetection;
            buttonClickDetection.clickThreshold = irMin + (irMax - irMin) / 2;
            buttonClickDetection.releaseThreshold = buttonClickDetection.clickThreshold - 10;
            Logger.info(SHOW_LOGS, TAG, `Set button thresholds: clickThreshold: ${buttonClickDetection.clickThreshold}, releaseThreshold: ${buttonClickDetection.releaseThreshold}`);
        } else {
            Logger.warn(SHOW_LOGS, TAG, "Couldn't get button thresholds, probably older fw version");
        }
    } catch (error) {
        Logger.warn(SHOW_LOGS, TAG, "Couldn't get button thresholds, probably older fw version");
    }
}

export default PublishedDataAnalyser;