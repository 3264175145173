import ProjectsGrid from "../../components/disposables/Grid";
import { GridItemProps } from "../../components/disposables/Grid/GridItem";
import Layout from "../../layout/Layout";
import { RaftTypeE } from "../../types/raft";
import styles from "./styles.module.css";
import { useEffect } from "react";

import GameIcon from "../../assets/nav-buttons/games.svg";
import GameIconHovered from "../../assets/nav-buttons/games-hovered.svg";

import LessonIcon from "../../assets/nav-buttons/lessons.svg";
import LessonIconHovered from "../../assets/nav-buttons/lessons-hovered.svg";

const imageStyle: React.CSSProperties = {
  width: "80%",
  height: "70%",
  alignSelf: "center",
  justifySelf: "center",
};

const imageX = "10%";
const imageY = "15%";

const gamesGridContent: GridItemProps[] = [
  {
    type: "game",
    title: "Codewheel", subtitle: "Have fun codewheeling!", contentTags: [RaftTypeE.COG], href: '/codewheel',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog"]
  },
  {
    type: "game",
    title: "Jumping Game", subtitle: "Jump!", contentTags: [RaftTypeE.COG], href: '/jumping-game',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog"]
  },
  {
    type: "game",
    title: "Maze", subtitle: "Can you find the exit?", contentTags: [RaftTypeE.COG], href: '/maze',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog"]
  },
];

const lessonsGridContent: GridItemProps[] = [
  {
    title: "Junior Blocks Tutorial",
    subtitle: "Learn how to use Cog with Marty",
    href: "/blocks-jr?tutorial=cog-and-marty-tutorial",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Cog", "Marty", "Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
  },
  {
    title: "Blocks Tutorial",
    subtitle: "Learn how to use Cog with Marty",
    href: "/blocks?tutorial=cog-and-marty-interaction",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Cog", "Marty", "Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
  },
];

const gamesGridContentComingSoon: GridItemProps[] = [
  {
    type: "game",
    title: "Helicopter Marty", subtitle: "Fly Marty through obstacles!", contentTags: [RaftTypeE.MARTY], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Marty"],
    isComingSoon: true
  },
  {
    type: "game",
    title: "Marty Goes Skiing", subtitle: "Hit the slopes with Marty!", contentTags: [RaftTypeE.MARTY], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Marty"],
    isComingSoon: true
  },
  {
    type: "game",
    title: "Lighting Designer", subtitle: "Create stunning lighting effects!", contentTags: [RaftTypeE.MARTY, RaftTypeE.COG], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog", "Marty"],
    isComingSoon: true
  },
  {
    type: "game",
    title: "Reaction Test Game", subtitle: "Test your reaction speed!", contentTags: [RaftTypeE.MARTY, RaftTypeE.COG], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog", "Marty"],
    isComingSoon: true
  },
  {
    type: "game",
    title: "Frogger-Style Platformer", subtitle: "Cross the road safely!", contentTags: [RaftTypeE.MARTY, RaftTypeE.COG], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog", "Marty"],
    isComingSoon: true
  },
  {
    type: "game",
    title: "Tambourine Rhythm Game", subtitle: "Follow the rhythm!", contentTags: [RaftTypeE.MARTY, RaftTypeE.COG], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog", "Marty"],
    isComingSoon: true
  },
  {
    type: "game",
    title: "Group Work - Cog Grid Screen", subtitle: "Collaborate using the cog grid!", contentTags: [RaftTypeE.COG], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog"],
    isComingSoon: true
  },
  {
    type: "game",
    title: "Play Notes", subtitle: "Make music with your notes!", contentTags: [RaftTypeE.COG], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog"],
    isComingSoon: true
  },
  {
    type: "game",
    title: "Code Wheel - Burglar Alarm", subtitle: "Protect your assets!", contentTags: [RaftTypeE.COG], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog"],
    isComingSoon: true
  },
  {
    type: "game",
    title: "Code Wheel - Special Effects", subtitle: "Create amazing effects!", contentTags: [RaftTypeE.COG], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog"],
    isComingSoon: true
  },
  {
    type: "game",
    title: "Code Wheel Challenge Mode", subtitle: "Put your skills to the test!", contentTags: [RaftTypeE.COG], href: '',
    SvgIcon: () => <image x={imageX} y={imageY} href={GameIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={GameIconHovered} style={imageStyle} />,
    tags: ["Cog"],
    isComingSoon: true
  }
];

const lessonsGridContentComingSoon: GridItemProps[] = [
  {
    title: "Make a tune",
    subtitle: "Learn how to make a tune with Cog",
    href: "",
    contentTags: [RaftTypeE.COG],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Make a light Show",
    subtitle: "Learn how to make a light show with Cog",
    href: "",
    contentTags: [RaftTypeE.COG],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Make a sound & light show",
    subtitle: "Learn how to make a light and sound show with Cog and Marty",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Etch-a-sketch",
    subtitle: "Etch-a-sketch with Marty",
    href: "",
    contentTags: [RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Make a game pt1. - walking simulator",
    subtitle: "Learn how to make a walking simulator with Marty",
    href: "",
    contentTags: [RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Parking sensor",
    subtitle: "Make a parking sensor with Marty",
    href: "",
    contentTags: [RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Object ounter / odometer",
    subtitle: "Make an object counter",
    href: "",
    contentTags: [RaftTypeE.MARTY, RaftTypeE.COG],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Night light - whole class light show",
    subtitle: "Collaborate to make a light show!",
    href: "",
    contentTags: [RaftTypeE.MARTY, RaftTypeE.COG],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Group work - whole class orchestra",
    subtitle: "Collaborate to make an orchestra!",
    href: "",
    contentTags: [RaftTypeE.COG],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Group work - Cog grid screen animation",
    subtitle: "Collaborate to make an animation with Cog",
    href: "",
    contentTags: [RaftTypeE.COG],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Make a game pt2. - jump platformer",
    subtitle: "Learn how to make a jump platformer with Cog",
    href: "",
    contentTags: [RaftTypeE.COG],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Marty simulator",
    subtitle: "Learn how to use the Marty simulator",
    href: "",
    contentTags: [RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Make a game pt3. - interactive puzzle game",
    subtitle: "Learn how to make an interactive puzzle game",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks Jr"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },

  {
    title: "Group work - traffic lights",
    subtitle: "Learn how to create a traffic light system",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Cogs reacting to each other",
    subtitle: "Explore how cogs can interact with each other",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Reaction test game",
    subtitle: "Build a fun reaction test game",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Dice",
    subtitle: "Create a digital dice roller",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Spirit level",
    subtitle: "Build a functional spirit level",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Make a game - skiing game",
    subtitle: "Design and develop a skiing game",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Musical instrument",
    subtitle: "Create your own musical instrument",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Fancy clock",
    subtitle: "Make a stylish and functional clock",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Trundle wheel",
    subtitle: "Build a trundle wheel for measuring distance",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Reactive light show",
    subtitle: "Create an interactive light show",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "PE circuit / lap timer / speed challenge",
    subtitle: "Measure speed and laps in physical exercises",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Make a game - Double cog rhythm game",
    subtitle: "Create a fun double cog rhythm game",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Small group work - quiz game",
    subtitle: "Develop an engaging quiz game",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Motion tracker",
    subtitle: "Build a motion tracking system",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "ML gesture sensing",
    subtitle: "Implement machine learning for gesture sensing",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  },
  {
    title: "Student project",
    subtitle: "Create your own project with Cog and Marty",
    href: "",
    contentTags: [RaftTypeE.COG, RaftTypeE.MARTY],
    type: "lesson",
    tags: ["Blocks"],
    SvgIcon: () => <image x={imageX} y={imageY} href={LessonIcon} style={imageStyle} />,
    SvgIconOnHover: () => <image x={imageX} y={imageY} href={LessonIconHovered} style={imageStyle} />,
    isComingSoon: true
  }
];


function ActivitiesScreen() {


  useEffect(() => {
    window.applicationManager.analyticsManager.logEvent("page", { page: "projects" });
  }, []);

  return (
    <Layout>
      <div className={styles.projectsSectionContainer}>
        <ProjectsGrid projects={[...gamesGridContent, ...lessonsGridContent]} />
        <hr style={{ width: "80%", margin: "0 auto", marginTop: "50px", marginBottom: "50px" }} />
        <h1 style={{ textAlign: "center", fontSize: "2em", marginBottom: "50px" }}>Coming Soon</h1>
        <hr style={{ width: "80%", margin: "0 auto", marginTop: "50px", marginBottom: "50px" }} />
        <ProjectsGrid projects={[...gamesGridContentComingSoon, ...lessonsGridContentComingSoon]} />
      </div>
    </Layout>
  );
}

export default ActivitiesScreen;
