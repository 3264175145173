import { RaftInfoEvents } from "../../types/events/raft-info";
import { WrapperSentMessage } from "../../types/communication-between-apps/wrapper-communication";
import Logger from "../../services/logger/Logger";
import { ConnectionAttemptResults, RaftTypeE } from "../../types/raft";
import { RaftObserver } from "../../application/RAFTs/RaftObserver";
import ConnectorInterface from "./ConnectorInterface";
import { ConnManager, RICLedLcdColours, SystemTypeCog, SystemTypeMarty } from "@robotical/roboticaljs";
import {
    RaftOKFail,
    RaftConnEvent,
    RaftUpdateEvent,
    RaftPublishEvent,
} from "@robotical/raftjs";

const SHOW_LOGS = true;
const TAG = "Connector";

class Connector implements ConnectorInterface {

    type = RaftTypeE.undefined;
    // RAFT

    // Observers
    protected _observers: { [key: string]: Array<RaftObserver> } = {};

    // Connection manager
    connManager: ConnManager;

    constructor(
        public id: string,
        connManager: ConnManager
    ) {

        this.connManager = connManager;
        // Set the listener function
        // Subscribe to connManager events
        this.connManager.setConnectionEventListener(
            (
                eventType: string,
                eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent,
                eventName: string,
                eventData: any
            ) => {
                if (eventType === "pub") {
                    // if it's a pub event, we are publishing it from _pubEventHandler method (in child classes)
                } else {
                    Logger.info(SHOW_LOGS, TAG, `eventType: ${eventType} eventEnum: ${eventEnum} eventName: ${eventName} eventData: ${eventData}`);
                    this.publish(eventType, eventEnum, eventName, eventData);
                }
                this.eventHandler(eventType, eventEnum, eventName, eventData);
            }
        );
    }

    /**
     * Connect to a RAFT after having found the RAFT type
     */
    async connect(): Promise<ConnectionAttemptResults> {
        throw new Error("Method should be implemented in child class");
    }

    /**
     * Get connected raft info
     */
    async getSystemInfo() {
        return this.connManager.getConnector().getRaftSystemUtils().getSystemInfo();
    }

    /**
     * Gets the RAFT name
     */
    async getRaftName() {
        return this.connManager.getConnector().getRaftSystemUtils().getFriendlyName()?.friendlyName || "";
    }

    /**
     * Send a REST message to the RAFT
     */
    async sendRestMessage(msg: string, params?: object): Promise<RaftOKFail> {
        return this.connManager.getConnector().sendRICRESTMsg(msg, params || {});
    }

    /**
     * Verify correct RAFT is selected
     */
    async verifyRaft(ledLcdColours: RICLedLcdColours): Promise<boolean> {
        const msgHandler = this.connManager.getConnector().getRaftSystemUtils().getMsgHandler();
        const onConnEvent = this.connManager.getConnector().onConnEvent.bind(this.connManager.getConnector());
        const isConnectedFn = this.connManager.isConnected.bind(this.connManager);

        return (this.connManager.getConnector().getSystemType() as SystemTypeMarty | SystemTypeCog)?.getLEDPatternChecker().checkCorrectRICStart(
            ledLcdColours,
            msgHandler,
            onConnEvent,
            isConnectedFn
        )
    }

    /**
     * Disconnect from a RAFT
     */
    async disconnect(): Promise<boolean> {
        // this.sendRestMessage('blerestart');
        await this.connManager.disconnect();
        return true;
    }

    /**
     * Stop verifying RAFT
     * @param confirmCorrectRIC - indicates if the correct RAFT was selected (user input)
     */
    async stopVerifyingRaft(confirmCorrectRIC: boolean) {
        if (confirmCorrectRIC) {
            // successful connection to RAFT
            // const ricSystem = this.connManager.getConnector().getRICSystem();

            // await ricSystem.getRICSystemInfo(true);

            // this.publish("conn", RaftInfoEvents.SYSTEM_INFO, "SystemInfo", systemInfo);
            // this.publish("conn", RaftInfoEvents.MARTY_VERSION, "MartyVersion", systemInfo.SystemVersion);
            // this.publish("conn", RaftInfoEvents.MARTY_SERIAL_NO, "MartySerialNo", systemInfo.SerialNo);
            // this.publish("conn", RaftInfoEvents.MARTY_IS_VERIFIED, "MartyIsVerified", true);

            // this.analytics_RobotStartSession(systemInfo); TODO: respond to the systemInfo event and call this function
            // this.isVerified = true; TODO: this event should be published from RICJS, listen for it in the front-end

            // const serialNo = systemInfo.SerialNo!;
            // TODO: respond to the MartySerialNo event and call this block
            // const isSerialNoRegisteredInWarranty = await isSerialNumberRegistered(serialNo); 
            // const dbManager = DatabaseManager.getInstance();
            // dbManager.isSerialNoRegisteredInWarranty = isSerialNoRegisteredInWarranty;
            // this.isSerialNoRegisteredInWarranty = isSerialNoRegisteredInWarranty;
            // if (isSerialNoRegisteredInWarranty) {
            //     addMartyNameToSerialNumber(serialNo, this.RICFriendlyName);
            // }

            // store ble conn performance 
            // as promise because we don't want to wait for it to finish
            // Promise.resolve().then(async () => {
            //     const bleConnPerf = await this.connManager.getConnector().checkConnPerformance();
            //     const dbManager = DatabaseManager.getInstance();
            //     const appDb = await dbManager.initializeOrGetDatabase(DatabaseEnum.APP, DatabaseEnum.APP) as AppDatabase;
            //     bleConnPerf && appDb.storeBlePerformance(bleConnPerf);

            //     this.soundStreamingStats.configureSoundStreamingForRobot(systemInfo, bleConnPerf);
            // });
        }
        return (this.connManager.getConnector().getSystemType() as SystemTypeMarty | SystemTypeCog)?.getLEDPatternChecker().checkCorrectRICStop(confirmCorrectRIC);
    }

    /**
     * Audio streaming
     */
    async streamAudio(streamContents: Uint8Array, clearExisting: boolean, duration: number) {
        const streamContentsNewUint8Array = new Uint8Array(streamContents);
        return this.connManager.getConnector().streamAudio(streamContentsNewUint8Array, clearExisting, duration);
    }

    /**
     * Publish an event to all observers
     * Set in RICConnector as an event listener callback
     */
    publish(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        eventData: any
    ): void {
        if (eventType === "raftinfo") {
            // should be handled from child class
            return;
        }
        window.WebAppCommunicator.sendMessageNoWait(WrapperSentMessage.RAFT_PUBLISHED_EVENT, { raftId: this.id, eventType, eventEnum, eventName, eventData });
        if (this._observers.hasOwnProperty(eventType)) {
            for (const observer of this._observers[eventType]) {
                observer.notify(eventType, eventEnum, eventName, eventData);
            }
        }
    }

    /**
    * Event handler 
    */
    async eventHandler(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        data: any
    ) {
        switch (eventType) {
            case "conn":
                this._connectionEventHandler(eventEnum as RaftConnEvent, eventName, data);
                break;
            case "update":
                this._updateEventHandler(eventEnum as RaftUpdateEvent, eventName, data);
                break;
            case "pub":
                this._pubEventHandler(eventEnum as RaftPublishEvent, eventName, data);
                break;
            default:
                break;
        }
    }

    /**
     * Connection Event Handler
     * This handles the events that are the same for all RAFT systems (e.g., Marty, Cog, etc.)
     * While the system-specific events are handled in the system-specific connectors
     */
    async _connectionEventHandler(
        eventEnum: RaftConnEvent,
        eventName: string,
        data: any
    ) {
        Logger.info(SHOW_LOGS, TAG, `Event: ${eventEnum} data: ${data}`);
        switch (eventEnum) {
            case RaftConnEvent.CONN_CONNECTED:
                // getting system info 
                let systemInfo = this.connManager.getConnector().getRaftSystemUtils().getCachedSystemInfo();
                if (!systemInfo) {
                    systemInfo = await this.connManager.getConnector().getRaftSystemUtils().getSystemInfo();
                }
                let raftName = this.connManager.getConnector().getRaftSystemUtils().getCachedRaftName() || this.connManager.getConnector().getRaftSystemUtils().getFriendlyName();
                if (!raftName) {
                    raftName = await this.connManager.getConnector().getRaftSystemUtils().getRaftName();
                }
                systemInfo.Friendly = raftName.friendlyName;
                this.publish("raftinfo", RaftInfoEvents.SYSTEM_INFO, "SystemInfo", { systemInfo });

                break;
            case RaftConnEvent.CONN_VERIFIED_CORRECT:
                // add a callback to display warning messages from RAFT to the user
                const raftMsgHandler = this.connManager.getConnector().getRaftMsgHandler();
                raftMsgHandler.reportMsgCallbacksSet(
                    "notifyCB",
                    (report) => this.publish("msg", RaftInfoEvents.RAFT_MSG, "RICMsg", report)
                );
                break;
            default:
                break;
        }
    }

    /**
     * Update Event Handler
     */
    async _updateEventHandler(
        eventEnum: RaftUpdateEvent,
        eventName: string,
        data: any
    ) {
        Logger.info(SHOW_LOGS, TAG, `Event: ${eventEnum} data: ${data}`);
        switch (eventEnum) {
            case RaftUpdateEvent.UPDATE_APP_UPDATE_REQUIRED:
                // do something
                break;
            default:
                break;
        }
    }

    /**
     * Pub Event Handler
     */
    async _pubEventHandler(
        eventEnum: RaftPublishEvent,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            default:
                break;
        }
    }


}

export default Connector;