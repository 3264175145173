import NavMenu from "../Navigation/NavMenu";
import styles from "./styles.module.css";
import { ReactComponent as BetaSignSVG } from "../../assets/beta-sign.svg";
import modalState from "../../state-observables/modal/ModalState";
// import BugReportModal from "../../components/modals/BugReportModal";
// import InstallButton from "../../components/InstallButton";
import { createElement, useState } from "react";
import VisibleContentToggles from "../../components/oneoffs/VisibleContentToggles";
import DetailedFeedbackModal from "../../components/modals/DetailedFeedbackModal";

type LayoutProps = {
  children: React.ReactNode;
};

export default function Layout({ children }: LayoutProps) {
  const [isMinimized, setIsMinimized] = useState(false);

  return (
    <main className={[styles.mainContainer, isMinimized ? styles.minimizedMainContainer : ""].join(" ")}>
      <header className={styles.mainHeaderContainer}>
        <span className={styles.title}>ROBOTICAL</span>
        <div className={styles.contentToggles}>
          <VisibleContentToggles />
        </div>
      </header>
      <section className={styles.mainNavigationContainer}>
        <NavMenu isNavMenuMinimized={isMinimized} setIsMinimized={setIsMinimized} />
      </section>
      <section className={styles.mainMainContentContainer}>
        {children}
      </section>
      <div
        className={styles.betaSignIconContainer}
        onClick={() =>
          modalState.setModal(createElement(DetailedFeedbackModal, { otherInfoObject: { from: "feedback button" } }), "Anonymous Bug Report")
        }
      >
        <BetaSignSVG />
      </div>
      {/* <InstallButton /> */}
    </main>
  );
}