import { createElement } from "react";
import modalState, { secondaryModalState } from "../../state-observables/modal/ModalState";
import { ConnectionAttemptResults, RaftConnectionMethod, RaftTypeE } from "../../types/raft";
import WebAppCommunicator from "../../wrapper-app/communicators/WebAppCommunicator";
import RAFT from "../RAFTs/RAFT";
import Marty from "../RAFTs/Marty/Marty";
import Cog from "../RAFTs/Cog/Cog";
import VerificationModal from "../../components/modals/VerificationModal";
import { FOUND_RAFT_ON_DISCOVERY_RESPONSE } from "../../types/phone-app-communicator";
import { raftFoundSubscriptionHelper } from "../RAFTs/raft-subscription-helpers";
import { AppSentMessage } from "../../types/communication-between-apps/wrapper-communication";
import { RaftObserver } from "../RAFTs/RaftObserver";
import { RaftInfoEvents } from "../../types/events/raft-info";
import VerificationModalPhoneApp from "../../components/modals/VerificationModalPhoneApp";
import {
    RaftConnEvent,
    RaftUpdateEvent,
    RaftPublishEvent,
} from "@robotical/raftjs";
import Logger from "../../services/logger/Logger";
import isPhoneApp from "../../utils/phone-app-communication/is-phone-app";
import DisconnectConfirmationModal from "../../components/modals/DisconnectConfirmation";
import { ConnectedRaftItem } from "../../store/SelectedRaftContext";
import Toaster from "../../utils/Toaster";
import { AnalyticsManager } from "../../analytics/AnalyticsManager";
import { ConnManager } from "@robotical/roboticaljs";
import ConnectingLoadingSpinnerModal from "../../components/modals/ConnectingLoadingSpinnerModal";
import draggableModalState from "../../state-observables/modal/DraggableModalState";
import SensorsDashboardModal from "../../components/modals/ SensorsDashboardModal";

const SHOW_LOGS = true;
const TAG = "ApplicationManager";
export default class ApplicationManager {

    // Observers
    private _observers: { [key: string]: Array<RaftObserver> } = {};

    // Communicator to the wrapper app
    public static wrapperAppCommunicator: WebAppCommunicator = new WebAppCommunicator();

    // Connected RICs
    public connectedRafts: { [key: string]: RAFT } = {};

    public connectedRaftsContext: ConnectedRaftItem[] = [];

    public returnToMainApp: () => void = () => { };

    private _router: any;

    setRouter(router: any) {
        this._router = router;
    }

    navigateTo(path: string) {
        if (this._router) {
            this._router.navigate(path);
        } else {
            Logger.error(SHOW_LOGS, TAG, "Router is not set");
        }
    }

    // Callback to call when a RAFT is selected (Phone App only)
    // We need that to make sure the connection button gets the selected RAFT once the user selects one
    ricSelectedCb: ((raft: RAFT) => void) | null = null;

    // RICNotificationsManager
    // private _ricNotificationsManager: RICNotificationsManager = new RICNotificationsManager(
    //     this
    // );

    // sound streaming stats
    // public soundStreamingStats = new MartySoundStreamingStats();

    // Updater removers: when marty disconnects
    // these functions will clear the time intervals
    // created for updating the sensors

    // all dbs for session data
    // public sessionDbs = new SessionsDBManager();

    // toaster
    public toaster = Toaster;

    // Analytics Manager
    public analyticsManager = AnalyticsManager.getInstance();

    public showBackHomeButton: () => void = () => { };
    public hideBackHomeButton: () => void = () => { };

    // connected raft context methods
    public connectedRaftContextMethods = {
        addConnectedRaft: (connectedRaft: ConnectedRaftItem) => { },
        removeConnectedRaft: (connectedRaftId: string) => { },
        setSelectedRaft: (connectedRaftId: string) => { },
    };

    isPhoneApp = isPhoneApp;

    constructor() {
        // super();
        // DatabaseManager.appStartSession();
        this.connectGenericMarty = this.connectGenericMarty.bind(this);
        this.connectGenericCog = this.connectGenericCog.bind(this);
    }

    createNewCog(id: string) {
        return new Cog(id);
    }

    createNewMarty(id: string) {
        return new Marty(id);
    }

    async connectGenericMarty(afterRaftConnectedCb: (raft: RAFT) => void) {
        return this.connectGeneric(afterRaftConnectedCb, [ConnManager.RICUUID]);
    }
    async connectGenericCog(afterRaftConnectedCb: (raft: RAFT) => void) {
        return this.connectGeneric(afterRaftConnectedCb, [ConnManager.COGUUID]);
    }

    /**
     * Generic Connect method
     * This method is called from various environments (connection button, blocksjr, blocks etc.)
     * Known issue: connecting to a raft from within a platform won't add the newly connected raft to the connectedRafts hook
     */
    async connectGeneric(afterRaftConnectedCb: (raft: RAFT) => void, uuids?: string[]) {
        if (!uuids) {
            uuids = [ConnManager.COGUUID, ConnManager.RICUUID];
        }
        if (isPhoneApp()) {
            try {
                await window.applicationManager.startDiscovery((newRaft) => {
                    this.connectedRaftContextMethods.addConnectedRaft({ id: newRaft.id, type: newRaft.type, name: newRaft.getFriendlyName() || "", isSelected: true });
                    afterRaftConnectedCb(newRaft);
                }, uuids)
            } catch (e) {
                Logger.error(SHOW_LOGS, TAG, `Failed to start discovery: ${e}`);
            }
        } else {
            secondaryModalState.setModal(createElement(ConnectingLoadingSpinnerModal, {}), "Connecting...", false);
            try {
                const newRaft = await window.applicationManager.connectToRIC(RaftConnectionMethod.WEB_BLE, uuids);
                if (newRaft) {
                    this.connectedRaftContextMethods.addConnectedRaft({ id: newRaft.id, type: newRaft.type, name: newRaft.getFriendlyName() || "", isSelected: true });
                    afterRaftConnectedCb(newRaft);
                }
            } catch (e) {
                Logger.error(SHOW_LOGS, TAG, `Failed to connect to new robot: ${e}`);
            }
            secondaryModalState.closeModal();
        }
    }

    /**
     * Disconnect from RAFT generic
     * This method is called from various environments (connection button, blocksjr, blocks etc.)
     */
    async disconnectGeneric(raft: RAFT, afterRaftDisconnectedCb?: () => void, skipConfirmation = false) {
        try {
            if (!skipConfirmation) {
                const confirmDisconnect = await modalState.setModal(createElement(DisconnectConfirmationModal),
                    `Are you sure you want to disconnect from your ${raft.getFriendlyName()}?`);
                if (!confirmDisconnect) {
                    return;
                }
            }
            await window.applicationManager.disconnectFromRaft(raft.id);
            if (afterRaftDisconnectedCb) {
                afterRaftDisconnectedCb();
            }
        } catch (e) {
            Logger.error(SHOW_LOGS, TAG, `Failed to disconnect from robot: ${e}`);
        }
    }

    /**
     * Selects a RAFT in Phone's verification modal
     */
    async selectRaft(ricToConnectTo: FOUND_RAFT_ON_DISCOVERY_RESPONSE['foundRIC'], method: RaftConnectionMethod) {
        const selectResults = await window.wrapperCommunicator.sendMessageAndWait<ConnectionAttemptResults>(AppSentMessage.RAFT_SELECT, { discoveredDevice: ricToConnectTo, method });

        if (selectResults.success) {
            const raftId = selectResults.data?.raftId;
            const raftType = selectResults.data?.raftType;
            if (!raftId || !raftType) {
                throw new Error(`RAFT ID or RAFT Type is missing: ${raftId} ${raftType}`);
            }
            let raft: RAFT;
            switch (raftType) {
                case RaftTypeE.MARTY:
                    raft = this.createNewMarty(raftId);
                    break;
                case RaftTypeE.COG:
                    raft = this.createNewCog(raftId);
                    break;
                default:
                    throw new Error("Unknown RAFT type");
            }
            this.connectedRafts[raftId] = raft;

            // get missed Connect event
            window.wrapperCommunicator.sendMessageAndWait<boolean>(AppSentMessage.GET_MISSED_CONN_EVENT, { raftId });


            // Note: we're verifying the RAFT from the place this functions is called

            await new Promise((resolve) => setTimeout(resolve, 200));

            if (this.ricSelectedCb) {
                this.ricSelectedCb(raft);
            }

            return raft;
        } else {
            Logger.warn(SHOW_LOGS, TAG, `Failed to select RAFT with id: ${ricToConnectTo._id}`);
        }

        return null;
    }

    async connectToRIC(method: RaftConnectionMethod, uuids: string[]) {
        const wasConnectedObj = await RAFT.connect(method, uuids);

        if (wasConnectedObj.success) {
            const raftId = wasConnectedObj.data?.raftId;
            const raftType = wasConnectedObj.data?.raftType;
            if (!raftId || !raftType) {
                throw new Error(`RAFT ID or RAFT Type is missing: ${raftId} ${raftType}`);
            }
            let raft: RAFT;
            switch (raftType) {
                case RaftTypeE.MARTY:
                    raft = this.createNewMarty(raftId);
                    break;
                case RaftTypeE.COG:
                    raft = this.createNewCog(raftId);
                    break;
                default:
                    throw new Error("Unknown RAFT type");
            }
            this.connectedRafts[raftId] = raft;

            // get missed Connect event
            window.wrapperCommunicator.sendMessageAndWait<boolean>(AppSentMessage.GET_MISSED_CONN_EVENT, { raftId });
            await new Promise((resolve) => setTimeout(resolve, 200));

            // start verification process
            modalState.setModal(createElement(VerificationModal, { connectedRAFT_: raft }), `Looking for ${raft.type}`);
            return raft;
        }

        return null;
    }


    /**
     * Toggles the Sensors Dashboard modal
     */
    toggleSensorsDashboard() {
        if (draggableModalState.modalContent) {
            return draggableModalState.closeModal();
        }
        return draggableModalState.setModal(createElement(SensorsDashboardModal), "Sensor Insights Hub (βETA)", "sensors-dashboard");
    }

    /**
     * Disconnect from RAFT
     */
    async disconnectFromRaft(raftId: string) {
        this.connectedRaftContextMethods.removeConnectedRaft(raftId);
        const raft = this.connectedRafts[raftId];
        if (!raft) {
            return;
        }
        await raft.disconnect();
        this._removeRaft(raftId, 3000);
    }

    /**
     * Removes raft after a certain time
     * Removes from the rics list after x seconds so that we can still get the last event
     */
    _removeRaft(raftId: string, timeout: number) {
        const ricRemovalTimeout = setTimeout(() => {
            delete this.connectedRafts[raftId];
            clearTimeout(ricRemovalTimeout);
        }, timeout);
    }

    /**
     * Start looking for rics to connect to
     * This is used from the Phone App only
     */
    async startDiscovery(ricSelectedCb: (raft: RAFT) => void, uuids: string[]) {
        this.ricSelectedCb = ricSelectedCb;
        const foundRICs: FOUND_RAFT_ON_DISCOVERY_RESPONSE['foundRIC'][] = [];
        raftFoundSubscriptionHelper().subscribe(({ discoveredDevice }: { discoveredDevice: FOUND_RAFT_ON_DISCOVERY_RESPONSE['foundRIC'] }) => {
            // if the discovered RAFT is already in the list, don't add it, just update the rssi
            const foundRICIdx = foundRICs.findIndex(ric_ => ric_._id === discoveredDevice._id);
            if (foundRICIdx !== -1) {
                foundRICs[foundRICIdx] = discoveredDevice;
            } else {
                foundRICs.push(discoveredDevice);
            }
            // foundRICs.sort((a, b) => b._rssi - a._rssi);
            modalState.setModal(createElement(VerificationModalPhoneApp, { foundRICs }), "Looking for RAFT");
        });
        modalState.setModal(createElement(VerificationModalPhoneApp, { foundRICs: [] }), "Looking for RAFT");
        const wasDiscoveryStarted = await window.wrapperCommunicator.sendMessageAndWait<boolean>(AppSentMessage.RAFT_START_DISCOVERY, { uuids });
        await modalState.setModal(createElement(VerificationModalPhoneApp, { foundRICs: [] }), "Looking for RAFT");
        if (!wasDiscoveryStarted) {
            return raftFoundSubscriptionHelper().unsubscribe();
        }
        raftFoundSubscriptionHelper().unsubscribe();
    }

    /**
     * Stοp discovery
     * When the user cancels the discovery process without having first connected to a robot
     */
    async stopDiscovery() {
        const wasDiscoveryStopped = await window.wrapperCommunicator.sendMessageAndWait<boolean>(AppSentMessage.RAFT_STOP_DISCOVERY);
        Logger.phoneAppLog(SHOW_LOGS, TAG, "Was discovery stopped: " + wasDiscoveryStopped);
    }



    /**
     * Stop verifying raft
     */
    async stopVerifyingRaft(raftId: string, isCorrectRIC: boolean) {
        await this.connectedRafts[raftId]?.stopVerifyingRaft(isCorrectRIC);
        this._removeRaft(raftId, 3000);
    }

    /**
     * Publishing events to observers
     * It could be either events we get from RAFT, or custom events related to the app
     */
    publish(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        eventData: any
    ): void {
        if (this._observers.hasOwnProperty(eventType)) {
            for (const observer of this._observers[eventType]) {
                observer.notify(eventType, eventEnum, eventName, eventData);
            }
        }
    }

    // observer
    subscribe(observer: RaftObserver, topics: Array<string>): void {
        for (const topic of topics) {
            if (!this._observers[topic]) {
                this._observers[topic] = [];
            }
            if (this._observers[topic].indexOf(observer) === -1) {
                this._observers[topic].push(observer);
            }
        }
    }

    unsubscribe(observer: RaftObserver): void {
        for (const topic in this._observers) {
            if (this._observers.hasOwnProperty(topic)) {
                const index = this._observers[topic].indexOf(observer);
                if (index !== -1) {
                    this._observers[topic].splice(index, 1);
                }
            }
        }
    }

    receivedRICEvent(raftId: string, eventType: any, eventEnum: any, eventName: any, eventData: any) {
        if (raftId === "scanner") {
            this.publish(eventType, eventEnum, eventName, eventData);
        } else {
            this.connectedRafts[raftId]?.receivedRICEvent(eventType, eventEnum, eventName, eventData);
        }
    }


    //======================//
    /* NATIVE COMMANDS ONLY */
    //======================//

    /**
     * Save the file on the device
     */
    saveFileOnDevice(fileName: string, base64: string) {
        if (!fileName || !base64) {
            return Logger.error(SHOW_LOGS, TAG, `fileName or base64 is missing: fileName: ${fileName}, base64: ${base64}`);
        }
        return window.wrapperCommunicator.sendMessageAndWait(AppSentMessage.SAVE_FILE_ON_DEVICE, { fileName, base64 });
    }

    /**
     * Save the file on the device's local storage
     */
    saveFileOnDeviceLocalStorage(dirname: string, fileName: string, base64: string) {
        if (!fileName || !base64) {
            return Logger.error(SHOW_LOGS, TAG, `fileName or base64 is missing: fileName: ${fileName}, base64: ${base64}`);
        }
        return window.wrapperCommunicator.sendMessageAndWait(AppSentMessage.SAVE_FILE_ON_DEVICE_LOCAL_STORAGE, { dirname, fileName, base64 });
    }

    /**
     * Load the file from the device's local storage
     */
    loadFileFromDeviceLocalStorage(dirname: string, fileName: string) {
        if (!fileName) {
            return Logger.error(SHOW_LOGS, TAG, `fileName is missing: fileName: ${fileName}`);
        }
        return window.wrapperCommunicator.sendMessageAndWait(AppSentMessage.LOAD_FILE_FROM_DEVICE_LOCAL_STORAGE, { dirname, fileName });
    }

    /**
     * Delete the file from the device's local storage
     */
    deleteFileFromDeviceLocalStorage(dirname: string, fileName: string) {
        if (!fileName) {
            return Logger.error(SHOW_LOGS, TAG, `fileName is missing: fileName: ${fileName}`);
        }
        return window.wrapperCommunicator.sendMessageAndWait(AppSentMessage.DELETE_FILE_FROM_DEVICE_LOCAL_STORAGE, { dirname, fileName });
    }

    /**
     * List the files from the device's local storage
     */
    async listFilesFromDeviceLocalStorage(dirname: string) {
        return await window.wrapperCommunicator.sendMessageAndWait(AppSentMessage.LIST_FILES_FROM_DEVICE_LOCAL_STORAGE, { dirname });
    }

    /**
     * Injects JS code to the phone app
     * This is used to call native functions from the web app
     */
    injectJS(jsCode: string) {
        return window.wrapperCommunicator.sendMessageAndWait(AppSentMessage.INJECT_JS, { jsCode });
    }
    /*======== END OF NATIVE COMMANDS ONLY ========*/


    //======================//
    /* UI */
    //======================//

    //======================//
    /* HELPERS */
    //======================//
    getTheCurrentlySelectedDeviceOrFirstOfItsKind(deviceType: RaftTypeE): RAFT | undefined {
        // checks if the currently selected device is a deviceType and returns it
        // if not, returns the first deviceType in the list (if there is one)
        const currentlySelectedId = this.connectedRaftsContext.find(connectedRaft => connectedRaft.isSelected)?.id;
        if (currentlySelectedId) {
            const selectedRaft = this.connectedRafts[currentlySelectedId];
            if (!selectedRaft) {
                return;
            }
            if (selectedRaft.type === deviceType) {
                // the currently selected device is a cog, return it
                return selectedRaft;
            } else {
                // the currently selected device is not a cog, find the first cog in the list
                let fistCog;
                for (const raftId in this.connectedRafts) {
                    if (this.connectedRafts[raftId].type === deviceType) {
                        fistCog = this.connectedRafts[raftId];
                        break;
                    }
                }
                return fistCog;
            }
        }
    }
}