import { useEffect, useState } from "react";
import "./styles.css";
import { IoMdHome } from 'react-icons/io';

type NavLayoutProps = {
  children: React.ReactNode | React.ReactNode[];
  onClickBack: () => void;
};
export default function NavLayout({ children, onClickBack }: NavLayoutProps) {
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(true);

  useEffect(() => {
    window.applicationManager.showBackHomeButton = () => {
      setIsBackButtonVisible(true);
    };
    window.applicationManager.hideBackHomeButton = () => {
      setIsBackButtonVisible(false);
    };

    return () => {
      window.applicationManager.showBackHomeButton = () => { };
      window.applicationManager.hideBackHomeButton = () => { };
    }
  }, []);

  const handleBack = () => {
    // eslint-disable-next-line no-restricted-globals
    const shouldExit = window.confirm("Are you sure you want to exit and return to the main app?");
    if (shouldExit) {
      onClickBack();
    }
  }

  return (
    <div className="nav-layout-container">
      {isBackButtonVisible && <div className="nav-layout-go-back-btn" onClick={handleBack}>
        <IoMdHome size={25} />
      </div>}
      {children}
    </div>
  );
}
