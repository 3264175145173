import { useNavigate } from "react-router-dom";
import NavLayout from "../../layout/Navigation/NavLayout";
import { createElement, useEffect } from "react";
import EmojiModal from "../../components/modals/EmojiModal";
import { emojiFeedbackModalState } from "../../state-observables/modal/ModalState";
import MartyController from "@robotical/rc-sequencer/dist/App";
import { RaftTypeE } from "../../types/raft";

function MartyControllerScreen() {
    const navigate = useNavigate();

    useEffect(() => {
        /* Check if marty is connected, if not, redirect to home */
        if (!window.applicationManager.getTheCurrentlySelectedDeviceOrFirstOfItsKind(RaftTypeE.MARTY)) {
            window.applicationManager.toaster.error("Please connect to Marty to access the Marty Controller.");
            return navigate("/");
        }
    }, []);

    useEffect(() => {
        window.applicationManager.analyticsManager.logEvent("page", { page: "marty-controller" });
    }, []);

    useEffect(() => {
        window.applicationManager.returnToMainApp = () => {
            const shouldExit = window.confirm("Are you sure you want to exit and return to the main app?");
            if (shouldExit) {
                navigate("/");
            }
        }
    }, []);

    const clickBackHandler = () => {
        navigate("/");
        const ModalComponent = createElement(EmojiModal, { otherInfoObject: { page: "marty-controller" } });
        emojiFeedbackModalState.setModal(ModalComponent, "");
        emojiFeedbackModalState.updateModalProps({ withCloseButton: true });
    };

    return (
        <NavLayout onClickBack={clickBackHandler}>
            <MartyController />
        </NavLayout>
    );
}

export default MartyControllerScreen;
