import { RaftInfoEvents } from "../../../types/events/raft-info";
import Logger from "../../../services/logger/Logger";
import { ConnectionAttemptResults, RaftTypeE } from "../../../types/raft";
import Connector from "../Connector";
import ConnectorInterface from "../ConnectorInterface";
import { RaftConnEvent, RaftPublishEvent, RaftUpdateEvent } from "@robotical/raftjs";
import { CogPublishedDataGetter, ConnManager, SimplifiedCogStateInfo, SystemTypeCog } from "@robotical/roboticaljs";
import { WrapperSentMessage } from "../../../types/communication-between-apps/wrapper-communication";

const SHOW_LOGS = true;
const TAG = "CogConnector";

class CogConnector extends Connector implements ConnectorInterface {
    type = RaftTypeE.COG;

    constructor(raftId: string, connManager: ConnManager) {
        super(raftId, connManager);
    }

    /**
     * Publish an event to all observers
     * Set in RICConnector as an event listener callback
     */
    publish(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        eventData: any
    ): void {
        window.WebAppCommunicator.sendMessageNoWait(WrapperSentMessage.RAFT_PUBLISHED_EVENT, { raftId: this.id, eventType, eventEnum, eventName, eventData });
        if (this._observers.hasOwnProperty(eventType)) {
            for (const observer of this._observers[eventType]) {
                observer.notify(eventType, eventEnum, eventName, eventData);
            }
        }
    }

    /**
     * Connect to a RAFT
     */
    async connect(): Promise<ConnectionAttemptResults> {

        // TODO 2022 - this code would normally run after LED pattern confirmed
        const sysInfoOk = await this.connManager.getConnector().getRaftSystemUtils().getSystemInfo();
        if (!sysInfoOk) {
            // this.emit(RAFT_REJECTED)
            return { success: false, reason: 'no_sys_info' };
        }

        return { success: true, reason: 'success' };
    }

    /**
     * Disconnect from Cog
     */
    async disconnect(): Promise<boolean> {
        // this.sendRestMessage('blerestart');
        return await super.disconnect();
    }

    /**
     * Event handler 
     */
    async eventHandler(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        data: any
    ) {
        super.eventHandler(eventType, eventEnum, eventName, data);
        switch (eventType) {
            case "conn":
                this.connectionEventHandler(eventEnum as RaftConnEvent, eventName, data);
                break;
            case "update":
                this.updateEventHandler(eventEnum as RaftUpdateEvent, eventName, data);
                break;
            case "pub":
                this.pubEventHandler(eventEnum as RaftPublishEvent, eventName, data);
                break;
            default:
                break;
        }
    }

    /**
     * Connection Event Handler
     */
    async connectionEventHandler(
        eventEnum: RaftConnEvent,
        eventName: string,
        data: any
    ) {
        Logger.info(SHOW_LOGS, TAG, `Event: ${eventEnum} data: ${data}`);
        switch (eventEnum) {
            case RaftConnEvent.CONN_CONNECTED:

                break;
            case RaftConnEvent.CONN_VERIFIED_CORRECT:

                break;
            default:
                break;
        }
    }

    /**
     * Update Event Handler
     */
    async updateEventHandler(
        eventEnum: RaftUpdateEvent,
        eventName: string,
        data: any
    ) {
        Logger.info(SHOW_LOGS, TAG, `Event: ${eventEnum} data: ${data}`);
        switch (eventEnum) {
            case RaftUpdateEvent.UPDATE_APP_UPDATE_REQUIRED:
                // do something
                break;
            default:
                break;
        }
    }

    /**
     * Pub Event Handler
     */
    async pubEventHandler(
        eventEnum: RaftPublishEvent,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            case RaftPublishEvent.PUBLISH_EVENT_DATA:
                // When data is published from Cog, we need to extract only the relevant data
                // instead of sending the whole stateInfo object
                const systemType = this.connManager.getConnector().getSystemType() as SystemTypeCog;
                if (systemType) {
                    const stateInfo = systemType.getStateInfo();
                    if (stateInfo.deviceManager) {
                        const simplifiedInfo: SimplifiedCogStateInfo = {
                            power: CogPublishedDataGetter.getPowerData(stateInfo.deviceManager),
                            accelerometer: CogPublishedDataGetter.getAccelerometerData(stateInfo.deviceManager),
                            light: CogPublishedDataGetter.getLightData(stateInfo.deviceManager),
                            gyroscope: CogPublishedDataGetter.getGyroscopeData(stateInfo.deviceManager)
                        };
                        this.publish("raftinfo", RaftInfoEvents.STATE_INFO, "StateInfo", { stateInfo: simplifiedInfo });
                    }
                }
                break;
            default:
                break;
        }
    }

}

export default CogConnector;
