import styles from './styles.module.css';
import { useVisibleContent } from '../../../store/VisibleContentContext';
import { RaftTypeE } from '../../../types/raft';
import CogToggleOffIcon from '../../../assets/other-icons/cog_toggle_off.svg';
import CogToggleOnIcon from '../../../assets/other-icons/cog_toggle_on.svg';
import MartyToggleOffIcon from '../../../assets/other-icons/marty_toggle_off.svg';
import MartyToggleOnIcon from '../../../assets/other-icons/marty_toggle_on.svg';

import MartySelectedIcon from '../../../assets/connect-button/marty_selected.svg';
import CogSelectedIcon from '../../../assets/connect-button/cog_selected.svg';
import MartyDeselectedIcon from '../../../assets/connect-button/marty_deselected.svg';
import CogDeselectedIcon from '../../../assets/connect-button/cog_deselected.svg';

const VisibleContentToggles = () => {
    const { visibleContent, setVisibleContent } = useVisibleContent();

    return (
        <>
            <div className={styles.toggleContainer} onClick={() => setVisibleContent(RaftTypeE.COG, !visibleContent.Cog)}>
                <img
                    src={visibleContent.Cog ? CogSelectedIcon : CogDeselectedIcon}
                    alt="Cog Icon"
                    className={styles.robotIcon}
                />
                <img
                    src={visibleContent.Cog ? CogToggleOnIcon : CogToggleOffIcon}
                    alt="Cog Toggle"
                    className={styles.toggleIcon}
                />
                <span className={styles.robotName}>Cog</span>
            </div>


            <div className={styles.toggleContainer} onClick={() => setVisibleContent(RaftTypeE.MARTY, !visibleContent.Marty)}>
                <img
                    src={visibleContent.Marty ? MartySelectedIcon : MartyDeselectedIcon}
                    alt="Marty Icon"
                    className={styles.robotIcon}
                />
                <img
                    src={visibleContent.Marty ? MartyToggleOnIcon : MartyToggleOffIcon}
                    alt="Marty Toggle"
                    className={styles.toggleIcon}
                />
                <span className={styles.robotName}>Marty</span>
            </div>
        </>
    );
};

export default VisibleContentToggles;