import Router from "./router";
import "./styles/typography.css";
import { createElement, useEffect, useState } from "react";
import modalState, { secondaryModalState, emojiFeedbackModalState } from "./state-observables/modal/ModalState";
import draggableModalState from "./state-observables/modal/DraggableModalState";
import DraggableResizableModal from "./components/oneoffs/DraggableModal";
import {
  ModalEventTopics,
  ModalStateData,
} from "./state-observables/modal/ModalObserver";

// import analyticsConsentChecking from "./utils/analytics/analytics-consent-checking";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { BrowserRouter } from "react-router-dom";
import WrapperCommunicator from "./application/communicators/WrapperCommunicator";
import WebAppCommunicator from "./wrapper-app/communicators/WebAppCommunicator";
import Modal from "./components/oneoffs/Modal";
import Logger from "./services/logger/Logger";
import ApplicationManager from "./application/ApplicationManager/ApplicationManager";
import EmojiModal from "./components/oneoffs/EmojiModal";

export default function App() {
  const [modalData, setModalData] = useState<null | ModalStateData>(null);
  const [draggableModalData, setDraggableModalData] = useState<null | ModalStateData>(null);
  const [secondaryModalData, setSecondaryModalData] = useState<null | ModalStateData>(null);
  const [emojiFeedbackModalData, setEmojiFeedbackModalData] = useState<null | ModalStateData>(null);
  const [shouldCloseModal, setShouldCloseModal] = useState(false);
  const [shouldCloseSecondaryModal, setShouldCloseSecondaryModal] = useState(false);
  const [shouldCloseDraggableModal, setShouldCloseDraggableModal] = useState(false);
  const [shouldCloseEmojiFeedbackModal, setShouldCloseEmojiFeedbackModal] = useState(false);

  useEffect(() => {
    // window.addEventListener("beforeunload", alertUser);
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", function (event) {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
    // return () => {
    //   window.removeEventListener("beforeunload", alertUser);
    // };
    window.applicationManager.toaster.info("Welcome! This app is currently in beta, so you may experience some changes or improvements along the way. We appreciate your feedback!", {
      autoClose: 10000,
      position: "top-center",
      closeButton: false,
    })
  }, []);

  useEffect(() => {
    // check if the app is running on an iOS mobile device
    // and if so, send an error message saying this is not supported
    // if (
    //   /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    //   //@ts-ignore
    //   !window.MSStream &&
    //   //@ts-ignore
    //   !window.navigator.standalone
    // ) {
    //   toast.error(
    //     "This web application is not supported on iOS devices. Please download Marty the Robot V2 from the Apple store instead.",
    //     {
    //       position: "top-center",
    //       autoClose: false
    //     }
    //   );
    // }
  }, []);

  const alertUser = (e: Event) => {
    e.preventDefault();
    return "Are you sure you want to exit?";
  };

  const modalSubscriptionHelper = {
    notify(eventTopic: ModalEventTopics, eventData: ModalStateData) {
      switch (eventTopic) {
        case "SetModal":
          setModalData(eventData);
          break;
        case "CloseModal":
          setShouldCloseModal(true);
          break;
        case "UpdateModalProps":
          setModalData((prevModalData) => {
            if (prevModalData) {
              return {
                ...prevModalData,
                ...eventData,
              };
            }
            return prevModalData;
          });
          break;
      }
    },
  };

  const secondaryModalSubscriptionHelper = {
    notify(eventTopic: ModalEventTopics, eventData: ModalStateData) {
      switch (eventTopic) {
        case "SetModal":
          setSecondaryModalData(eventData);
          break;
        case "CloseModal":
          setShouldCloseSecondaryModal(true);
          break;
        case "UpdateModalProps":
          setSecondaryModalData((prevModalData) => {
            if (prevModalData) {
              return {
                ...prevModalData,
                ...eventData,
              };
            }
            return prevModalData;
          });
          break;
      }
    },
  };

  const draggableModalSubscriptionHelper = {
    notify(eventTopic: ModalEventTopics, eventData: ModalStateData) {
      switch (eventTopic) {
        case "SetModal":
          setDraggableModalData(eventData);
          break;
        case "CloseModal":
          setShouldCloseDraggableModal(true);
          break;
      }
    },
  };

  const emojiFeedbackModalSubscriptionHelper = {
    notify(eventTopic: ModalEventTopics, eventData: ModalStateData) {
      switch (eventTopic) {
        case "SetModal":
          setEmojiFeedbackModalData(eventData);
          break;
        case "CloseModal":
          setShouldCloseEmojiFeedbackModal(true);
          break;
        case "UpdateModalProps":
          setEmojiFeedbackModalData((prevModalData) => {
            if (prevModalData) {
              return {
                ...prevModalData,
                ...eventData,
              };
            }
            return prevModalData;
          });
          break;
      }
    },
  };

  const resetModal = () => {
    setShouldCloseModal(false);
    setModalData(null);
  };

  const resetSecondaryModal = () => {
    setShouldCloseSecondaryModal(false);
    setSecondaryModalData(null);
  };

  const closeDraggableModal = () => {
    setShouldCloseDraggableModal(false);
    setDraggableModalData(null);
  };

  const resetEmojiFeedbackModal = () => {
    setShouldCloseEmojiFeedbackModal(false);
    setEmojiFeedbackModalData(null);
  };

  // Create subscription to the modal states
  useEffect(() => {
    // Subscribe
    modalState.subscribe(modalSubscriptionHelper, ["SetModal", "CloseModal", "UpdateModalProps"]);
    secondaryModalState.subscribe(secondaryModalSubscriptionHelper, ["SetModal", "CloseModal", "UpdateModalProps"]);
    draggableModalState.subscribe(draggableModalSubscriptionHelper, ["SetModal", "CloseModal"]);
    emojiFeedbackModalState.subscribe(emojiFeedbackModalSubscriptionHelper, ["SetModal", "CloseModal", "UpdateModalProps"]);
    // Return unsubscribe function
    return () => {
      modalState.unsubscribe(modalSubscriptionHelper);
      secondaryModalState.unsubscribe(secondaryModalSubscriptionHelper);
      draggableModalState.unsubscribe(draggableModalSubscriptionHelper);
      emojiFeedbackModalState.unsubscribe(emojiFeedbackModalSubscriptionHelper);
    };
  }, []);

  // checking if there is an analytics consent cookie decision.
  // if there is not, we present the analytics consent modal
  useEffect(() => {
    // analyticsConsentChecking();
  }, []);

  const ModalContent = modalData?.modalContent;
  const SecondaryModalContent = secondaryModalData?.modalContent;
  const DraggableModalContent = draggableModalData?.modalContent;
  const EmojiFeedbackModalContent = emojiFeedbackModalData?.modalContent;

  return (
    <>
      {/* <div style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        color: "white",
        padding: "10px",
        textAlign: "center",
        display: "grid",
        placeItems: "center",
        fontSize: "3.5rem",
        zIndex: 9999,
      }}>
        Testing starts Monday, October 21st! 🚀​
      </div> */}
      <BrowserRouter>
        <ToastContainer />
        {!!ModalContent && (
          <Modal
            title={modalData ? modalData.modalTitle || "" : ""}
            modalResetter={resetModal}
            shouldCloseModal={shouldCloseModal}
            withLogo
            withCloseButton={modalData?.withCloseButton}
          >
            {ModalContent}
          </Modal>
        )}

        {!!SecondaryModalContent && (
          <Modal
            title={secondaryModalData ? secondaryModalData.modalTitle || "" : ""}
            modalResetter={resetSecondaryModal}
            shouldCloseModal={shouldCloseSecondaryModal}
            withLogo={secondaryModalData?.withLogo}
            containerId="secondary-modal-root"
            withCloseButton={secondaryModalData?.withCloseButton}
          >
            {SecondaryModalContent}
          </Modal>
        )}
        {!!EmojiFeedbackModalContent && (
          <EmojiModal
            title={emojiFeedbackModalData ? emojiFeedbackModalData.modalTitle || "" : ""}
            modalResetter={resetEmojiFeedbackModal}
            shouldCloseModal={shouldCloseEmojiFeedbackModal}
            containerId="emoji-feedback-modal-root"
            withCloseButton={emojiFeedbackModalData?.withCloseButton}
          >
            {EmojiFeedbackModalContent}
          </EmojiModal>
        )}

        {!!DraggableModalContent && (
          <DraggableResizableModal modalClose={closeDraggableModal} shouldCloseModal={shouldCloseDraggableModal}
            title={draggableModalData.modalTitle || ""} newWindowLink={draggableModalData.newWindowLink}
          >
            {DraggableModalContent}
          </DraggableResizableModal>
        )}
        { }
        <Router />
      </BrowserRouter>
    </>
  );
}


// TODO: transfer the setup of these object to a different file
// const marty = new Marty();
// window.marty = marty;

window.applicationManager = new ApplicationManager();

window.wrapperCommunicator = new WrapperCommunicator();

window.WebAppCommunicator = new WebAppCommunicator();
