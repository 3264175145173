import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";
import { FaTimes } from 'react-icons/fa';
import styles from "./styles.module.css";
import Logo from "../../disposables/Logo";
import { useEmojiModalDisplay } from "../../../store/EmojiModalDisplayContext";

type ModalPropsTypes = {
  children: React.ReactNode | null;
  title: string;
  shouldCloseModal: boolean;
  withLogo?: boolean;
  containerId?: string;
  modalResetter: () => void;
  withCloseButton?: boolean;
};

function EmojiModal({
  children,
  title,
  shouldCloseModal,
  withLogo = false,
  modalResetter,
  withCloseButton,
  containerId = "emoji-feedback-modal-root",
}: ModalPropsTypes) {
  const [isBrowser, setIsBrowser] = useState(false);
  const [closing, setClosing] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const { hasDisplayed, setHasDisplayed } = useEmojiModalDisplay();

  useEffect(() => {
    setIsBrowser(true);
    if (showModal) {
      // window.scrollTo({ top: 0 });
      // document
      //   .getElementsByTagName("body")[0]
      //   .setAttribute("style", "overflow: hidden");
    }
  }, [showModal]);

  useEffect(() => {
    if (shouldCloseModal) {
      closeModalHandler();
    }
  }, [shouldCloseModal]);


  if (hasDisplayed.current) {
    return null;
  }

  const closeModalHandler = () => {
    setClosing(true);
    const timer = setTimeout(() => {
      // document
      //   .getElementsByTagName("body")[0]
      //   .setAttribute("style", "overflow: auto");
      setShowModal(false);
      setHasDisplayed(true);
      modalResetter();
      clearTimeout(timer);
    }, 700);
  };

  const modalContent = showModal ? (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modal} ${closing ? styles.modalClosing : styles.modalOpening}`}>
        <div className={styles.modalHeader}></div>
        {withCloseButton && <button onClick={closeModalHandler} className={styles.closeButton}><FaTimes /></button>}
        {withLogo && <div className={styles.modalLogoContainer}><Logo /></div>}
        {title && <h2 className={styles.title}>{title}</h2>}
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  ) : null;

  if (isBrowser && showModal) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById(containerId)!
    );
  } else {
    return null;
  }
}
export default EmojiModal;
