import {
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import HomeScreen from "../screens/Home";
import ActivitiesScreen from "../screens/Activities";
import BlocksJR from "../screens/BlocksJR";
import Blocks from "../screens/Blocks";
import CodewheelScreen from "../screens/CodewheelScreen";
import JumpingGameScreen from "../screens/JumpingGameScreen";
import MazeScreen from "../screens/MazeScreen";
import MartyControllerScreen from "../screens/MartyController";
import SensorsDashboard from "../screens/SensorsDashboard";
import { useEffect } from "react";


export default function Router() {

  const navigate = useNavigate();
  useEffect(() => {
    window.applicationManager.setRouter({ navigate });
  }, [navigate]);

  // const connectionCtx = useContext(MartyConnectionContext);
  return (
    <Routes>
      <Route path="/home" element={<HomeScreen />} />
      {/* <Route
          path="/configuration"
          element={
            connectionCtx.isConnected ? (
            <ConfigurationNavigator key={new Date().getTime()}/>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />*/}
      <Route path="/marty-controller" element={<MartyControllerScreen />}/>
      <Route path="/maze" element={<MazeScreen />} />
      <Route path="/jumping-game" element={<JumpingGameScreen />} />
      <Route path="/codewheel" element={<CodewheelScreen />} />
      <Route path="/blocks-jr" element={<BlocksJR />} />
      <Route path="/blocks" element={<Blocks />} />
      <Route path="/activities" element={<ActivitiesScreen />} />
      <Route path="/sensors-dashboard" element={<SensorsDashboard />} />
      {/*   <Route path="/help" element={<HelpNavigator />} />
        <Route path="/unplugged" element={<UnpluggedScreen />} />
        <Route path="/warranty-terms" element={<WarrantyTerms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/sensors-dashboard" element={<SensorsDashboard />} /> */}

      <Route path="*" element={<Navigate to="/home" replace />} />

    </Routes>
  );
}
