import { useCallback, useState } from 'react';
import Logger from '../../../../services/logger/Logger';
import { useVisibleContent } from '../../../../store/VisibleContentContext';
import { RaftTypeE } from '../../../../types/raft';
import styles from './styles.module.css';
import { useNavigate } from "react-router-dom";
import isPhoneApp from '../../../../utils/phone-app-communication/is-phone-app';
import { addHoverClass } from '../../../../utils/hoverClass';

export type GridItemProps = {
    title: string;
    subtitle?: string;
    href?: string;
    onClick?: () => void;
    contentTags?: RaftTypeE[];
    SvgIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    tags?: ("Unplugged" | "Controller" | "Blocks Jr" | "Blocks" | "Sensor Dashboard" | "Cog" | "Marty")[];
    type: "game" | "platform" | "project" | "lesson";
    SvgIconOnHover?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    isComingSoon?: boolean;
};

const SHOW_LOGS = true;
const TAG = "GridItem";

export default function GridItem({
    title,
    subtitle,
    href,
    onClick,
    contentTags,
    SvgIcon,
    tags,
    type,
    SvgIconOnHover,
    isComingSoon
}: GridItemProps) {
    const { visibleContent } = useVisibleContent();
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const handleHover = (hovered: boolean) => {
        if (isPhoneApp()) {
            return;
        }
        setIsHovered(hovered);
    }

    //   const visibleContent: {
    //     Cog: boolean;
    //     Marty: boolean;
    // }

    // Determine the wrapper component based on href and onClick
    const Wrapper = useCallback(({ children }: { children: React.ReactNode }) => {
        if (href) {
            return (
                <div className={addHoverClass(styles.gridItemContainer, styles.gridItemContainerWithHover)} onClick={() => {
                    navigate(href);
                }} onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
                    {children}
                </div>
            );
        } else if (onClick) {
            return (
                <div className={addHoverClass(styles.gridItemContainer, styles.gridItemContainerWithHover)} onClick={onClick} onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
                    {children}
                </div>
            );
        } else {
            return <div className={addHoverClass(styles.gridItemContainer, styles.gridItemContainerWithHover)} onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>{children}</div>;
        }
    }, [href, onClick, navigate, handleHover]);

    // Check if the item should be visible based on contentTags and visibleContent
    if (contentTags && !contentTags.some((tag) => {
        if (tag === RaftTypeE.undefined) {
            return true;
        }
        return visibleContent[tag];
    })) {
        return null;
    }

    // Ensure href and onClick are mutually exclusive
    if (href && onClick) {
        Logger.error(SHOW_LOGS, TAG, "GridItem cannot have both 'href' and 'onClick' props.");
        return null;
    }

    return (
        <Wrapper>
            {isComingSoon && <div className={styles.comingSoonOverlay} onClick={(e)=> e.stopPropagation()}>Not Quite Ready Yet!</div>}
            <div className={styles.gridItemIconContainer}>
                <svg className={styles.gridItemIcon}>{(isHovered && SvgIconOnHover) ? <SvgIconOnHover /> : SvgIcon && <SvgIcon />}</svg>
            </div>
            <div className={styles.gridItemTextContainer}>
                <h3 className={styles.gridItemTitle}>{title}</h3>
                <p className={styles.gridItemSubtitle}>{subtitle}</p>
            </div>
            <div className={styles.gridItemTagsContainer}>
                {(tags || []).map((tag, index) => {
                    let tagClass = styles.gridItemTag;
                    switch (tag) {
                        case "Unplugged":
                            tagClass = `${styles.gridItemTagCircle} ${styles.gridItemTagUnplugged}`;
                            break;
                        case "Controller":
                            tagClass = `${styles.gridItemTagCircle} ${styles.gridItemTagController}`;
                            break;
                        case "Blocks Jr":
                            tagClass = `${styles.gridItemTagCircle} ${styles.gridItemTagBlocksJr}`;
                            break;
                        case "Blocks":
                            tagClass = `${styles.gridItemTagCircle} ${styles.gridItemTagBlocks}`;
                            break;
                        case "Sensor Dashboard":
                            tagClass = `${styles.gridItemTagCircle} ${styles.gridItemTagSensorDashboard}`;
                            break;
                        case "Cog":
                            tagClass = `${styles.gridItemTagCircle} ${styles.gridItemTagCog}`;
                            break;
                        case "Marty":
                            tagClass = `${styles.gridItemTagCircle} ${styles.gridItemTagMarty}`;
                            break;
                    }
                    return (
                        <span key={index} className={styles.gridItemTag}>
                            <span className={tagClass}></span>
                            {tag}
                        </span>
                    );
                })}
            </div>
        </Wrapper>
    );
}