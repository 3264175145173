import { useEffect, useState } from "react";
import "./styles.css";
import { ReactComponent as BluetoothSVG } from "../../../assets/configuration/bluetooth.svg";
import { MAIN_BLUE } from "../../../styles/colors";
import modalState from "../../../state-observables/modal/ModalState";

import RICSignal from "../../oneoffs/RaftSignal";
import SimpleButton from "../../disposables/buttons/SimpleButton";
import LEDs from "../../oneoffs/LEDs";
import RAFT from "../../../application/RAFTs/RAFT";
import { raftConnectingSubscriptionHelper } from "../../../application/RAFTs/raft-subscription-helpers";
import { useConnectedRafts } from "../../../store/SelectedRaftContext";

const SHOW_LOGS = true;
const TAG = "VerificationModal";

function VerificationModal({ connectedRAFT_ }: { connectedRAFT_: RAFT }) {
  const [randomColours, setRandomColours] = useState<string[]>([]);
  const [connectedRaft, setConnectedRaft] = useState<RAFT | undefined>(connectedRAFT_);
  const [RSSI, setRSSI] = useState(-200);
  const { removeConnectedRaft } = useConnectedRafts();

  useEffect(() => {
    /* Subscribe to the connectedRaft's SIGNAL strength */
    if (!connectedRaft) return;
    const signalUpdateTimout = setInterval(() => {
      setRSSI(connectedRaft.getRSSI());
    }, 1000);
    return () => clearInterval(signalUpdateTimout);
  }, [connectedRaft]);

  const onCancel = async () => {
    // close modal, stop verification process, disconnect, scan again
    modalState.closeModal();
    if (!connectedRaft) return;
    removeConnectedRaft(connectedRaft.id);
    await window.applicationManager.stopVerifyingRaft(connectedRaft.id, false);
    await connectedRaft.disconnect();
  };

  const onYes = async () => {
    modalState.closeModal();
    await connectedRaft?.stopVerifyingRaft(true);
    return;
  }

  useEffect(() => {
    /* Subscribe to the connectedRaft's Connection state */
    if (!connectedRaft) return;
    raftConnectingSubscriptionHelper(connectedRaft).subscribe(setRandomColours);
    return () => {
      raftConnectingSubscriptionHelper(connectedRaft).unsubscribe();
    };
  }, [connectedRaft]);

  useEffect(() => {
    /* Verify the connectedRaft once it's connected */
    connectedRaft?.verifyRaft();
  }, [connectedRaft]);

  let contentJSX = <div className="verification-modal-container">
    <div className="verification-modal-martys-container">
      <div className="verification-modal-marty-name-row-container">
        <p className="verification-modal-marty-name">{connectedRaft?.getFriendlyName()}</p>
        <div className="verification-modal-marty-signal-container">
          <RICSignal signalStrength={RSSI} />
        </div>
        <BluetoothSVG fill={MAIN_BLUE} />
      </div>
      <p className="verification-modal-martys-back-hint">
        Look on {connectedRaft?.type}'s back, is it displaying these lights?
      </p>
      <div className="verification-modal-led-row-container">
        <LEDs coloursArr={randomColours} connectedRaft={connectedRaft} />
        <SimpleButton
          onClick={onCancel}
          title="No"
          borderColour="red"
          colour="white"
          textColour="red"
        />
        <SimpleButton onClick={onYes} title="YES" />
      </div>
    </div>
    <div className="verification-modal-bottom-btns-container">
      <div className="dummy-gap"></div>
      <SimpleButton onClick={onCancel} title="CANCEL" />
    </div>
  </div>

  return contentJSX;
}

export default VerificationModal;
