import React, { createElement } from 'react';
import styles from "./styles.module.css";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavLayout from "../../layout/Navigation/NavLayout";
import Logger from "../../services/logger/Logger";
import EmojiModal from '../../components/modals/EmojiModal';
import { emojiFeedbackModalState } from '../../state-observables/modal/ModalState';
import LoadingSpinnerMarty from '../../components/disposables/LoadingSpinnerMarty';
import draggableModalState from '../../state-observables/modal/DraggableModalState';

const TAG = "Blocks";
const SHOW_LOGS = true;

function Blocks() {
  const [isBlocksLoaded, setIsBlocksLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const handleBlocksLoad = (e: SyntheticEvent<HTMLDivElement>) => {
    setIsBlocksLoaded((oldState) => oldState + 1);
    setIsLoading(false);
    // Focus on the object
    const object = e.target as HTMLObjectElement;
    object.contentDocument?.body.focus();
    object.contentWindow?.focus();

    // Planting the application manager object in the window object
    const blocksWindow = object.contentWindow;
    if (blocksWindow) {
      blocksWindow.applicationManager = window.applicationManager;
    }
  };

  useEffect(() => {
    window.applicationManager.returnToMainApp = () => {
      const shouldExit = window.confirm("Are you sure you want to exit and return to the main app?");
      if (shouldExit) {
        navigate("/");
      }
    }
    return () => {
      if (draggableModalState.modalContent) {
        return draggableModalState.closeModal();
      }
    };
  }, [isBlocksLoaded]);

  useEffect(() => {
    window.applicationManager.analyticsManager.logEvent("page", { page: "blocks" });
  }, []);

  const clickBackHandler = () => {
    navigate("/");
    const ModalComponent = createElement(EmojiModal, { otherInfoObject: { page: "blocks" } });
    emojiFeedbackModalState.setModal(ModalComponent, "");
    emojiFeedbackModalState.updateModalProps({ withCloseButton: true });
  };

  return (
    <NavLayout onClickBack={clickBackHandler}>
      <main className={styles.mainWindow}>
        <div className={styles.contentWindow}>
          {isLoading && <LoadingSpinnerMarty />}
          <ObjectWrapper
            data={`./MartyBlocks/index.html?${params.toString()}`}
            onLoad={handleBlocksLoad}
            style={{ width: "100%", height: "100%" }}
            id="blocksObject"
          />
        </div>
      </main>
    </NavLayout>
  );
}

export default Blocks;


type ObjectWrapperProps = {
  data: string;
  onLoad: (e: any) => void;
  style: React.CSSProperties;
  id: string;
};

class ObjectWrapper extends React.Component<ObjectWrapperProps> {
  objectRef: React.RefObject<HTMLObjectElement>;

  constructor(props: ObjectWrapperProps) {
    super(props);
    this.objectRef = React.createRef();
  }

  componentWillUnmount() {
    // Access the DOM element before it unmounts
    const objectElement = this.objectRef.current;
    if (objectElement) {
      const contentWindow = objectElement.contentWindow;
      if (contentWindow) {
        console.log("Clearing all subscriptions");
        // @ts-ignore
        contentWindow.raftManager?.clearAllSubscriptions();
      }
    }
  }

  render() {

    return (
      <object
        ref={this.objectRef}
        type="text/html"
        data={this.props.data}
        onLoad={this.props.onLoad}
        style={this.props.style}
        id={this.props.id}
      />
    );
  }
}

