import { Fragment, useEffect, useState } from "react";
import "./styles.css";
import { ReactComponent as BluetoothSVG } from "../../../assets/configuration/bluetooth.svg";
import { ReactComponent as RefreshSVG } from "../../../assets/refresh.svg";
import { MAIN_BLUE, WHITE } from "../../../styles/colors";
import modalState from "../../../state-observables/modal/ModalState";
import { FOUND_RAFT_ON_DISCOVERY_RESPONSE } from "../../../types/phone-app-communicator";
import RICSignal from "../../oneoffs/RaftSignal";
import SimpleButton from "../../disposables/buttons/SimpleButton";
import LEDs from "../../oneoffs/LEDs";
import SVGImageButton from "../../disposables/buttons/SVGImageButton";
import { raftConnectingSubscriptionHelper, raftDiscoveryFinishedSubscriptionHelper, raftDiscoveryStartedSubscriptionHelper } from "../../../application/RAFTs/raft-subscription-helpers";
import isPhoneApp from "../../../utils/phone-app-communication/is-phone-app";
import LoadingSpinner from "../../disposables/LoadingSpinner";
import RAFT from "../../../application/RAFTs/RAFT";
import Logger from "../../../services/logger/Logger";
import { RaftConnectionMethod } from "../../../types/raft";
import { useConnectedRafts } from "../../../store/SelectedRaftContext";
import { ConnManager } from "@robotical/roboticaljs";


const SHOW_LOGS = true;
const TAG = "VerificationModalPhone";

function VerificationModalPhoneApp({ foundRICs }: { foundRICs: FOUND_RAFT_ON_DISCOVERY_RESPONSE["foundRIC"][] }) {
  const [randomColours, setRandomColours] = useState<string[]>([]);
  const [selectedRICIdx, setSelectedRICIdx] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isScanning, setIsScanning] = useState(isPhoneApp());
  const [connectedRaft, setConnectedRaft] = useState<RAFT | undefined>();
  const { removeConnectedRaft } = useConnectedRafts();

  useEffect(() => {
    raftDiscoveryFinishedSubscriptionHelper().subscribe(() => setIsScanning(false));
    raftDiscoveryStartedSubscriptionHelper().subscribe(() => setIsScanning(true));
    return () => {
      raftDiscoveryFinishedSubscriptionHelper().unsubscribe();
      raftDiscoveryStartedSubscriptionHelper().unsubscribe();
    }
  }, []);

  const onCancel = async () => {
    // close modal, stop verification process, disconnect
    if (connectedRaft) {
      await window.applicationManager.stopVerifyingRaft(connectedRaft.id, false);
      await window.applicationManager.disconnectFromRaft(connectedRaft.id);
      removeConnectedRaft(connectedRaft.id);
    } else {
      await window.applicationManager.stopDiscovery();
    }
    modalState.closeModal();
  };

  const onScanAgain = async () => {
    if (connectedRaft) {
      await window.applicationManager.stopVerifyingRaft(connectedRaft.id, false);
      await window.applicationManager.disconnectFromRaft(connectedRaft.id);
    }
    setSelectedRICIdx(-1);
    setConnectedRaft(undefined);
    if (window.applicationManager.ricSelectedCb) {
      await window.applicationManager.startDiscovery(window.applicationManager.ricSelectedCb, [ConnManager.COGUUID, ConnManager.RICUUID]);
    }
  };

  const onYes = async () => {
    modalState.closeModal();
    await connectedRaft?.stopVerifyingRaft(true);
    return;
  }

  useEffect(() => {
    if (!connectedRaft) return;
    raftConnectingSubscriptionHelper(connectedRaft).subscribe(setRandomColours);
    connectedRaft?.verifyRaftPhoneApp(foundRICs[selectedRICIdx]);
    return () => {
      raftConnectingSubscriptionHelper(connectedRaft).unsubscribe();
    };
  }, [connectedRaft]);


  const onSelectRaft = async (raftIdx: number) => {
    setIsLoading(true);
    // first disconnect from the current raft
    if (connectedRaft) {
      await window.applicationManager.stopVerifyingRaft(connectedRaft.id, false);
      await window.applicationManager.disconnectFromRaft(connectedRaft.id);
      removeConnectedRaft(connectedRaft.id);
    }
    setSelectedRICIdx(raftIdx);

    if (foundRICs) {
      const foundRaft = foundRICs[raftIdx];
      const newRaft = await window.applicationManager.selectRaft(foundRaft, RaftConnectionMethod.PHONE_BLE);
      if (!newRaft) return;
      setConnectedRaft(newRaft);
    }
    setIsLoading(false);
  }

  let contentJSX = null;

  if (foundRICs.length === 0) {
    contentJSX = <div className="verification-modal-container">
      {!isScanning && <p style={{ alignSelf: 'center' }}>No Robots Found</p>}
      <div className="verification-modal-martys-container">
        {isScanning && <div style={{ margin: "auto", width: "50px", height: "50px" }}> <LoadingSpinner /> </div>}
      </div>
      <div className="verification-modal-bottom-btns-container">
        <SVGImageButton
          onClick={onScanAgain}
          title="SCAN AGAIN"
          SVGImage={RefreshSVG}
          backgroundColour={MAIN_BLUE}
          titleColour={WHITE}
          SVGColour={WHITE}
        />
        <div className="dummy-gap"></div>
        <SimpleButton onClick={onCancel} title="CANCEL" />
      </div>
    </div>
  } else {
    contentJSX = <div className="verification-modal-container">
      <div className="verification-modal-martys-container">
        {isScanning && <div style={{ margin: "auto", width: "50px", height: "50px" }}> <LoadingSpinner /> </div>}
        {foundRICs.map((raftObj, raftIdx) => {
          return <Fragment key={raftIdx}>
            <div className="verification-modal-marty-name-row-container" onClick={() => onSelectRaft(raftIdx)}>
              <p className="verification-modal-marty-name">{raftObj._localName}</p>
              <div className="verification-modal-marty-signal-container">
                <RICSignal signalStrength={raftObj._rssi} />
              </div>
              <BluetoothSVG fill={MAIN_BLUE} />
            </div>
            {selectedRICIdx === raftIdx && <div>
              <p className="verification-modal-martys-back-hint">
                {isLoading ? "Loading..." : `Look on ${connectedRaft?.type}'s back, is it displaying these lights?`}
              </p>
              <div className="verification-modal-led-row-container">
                <LEDs coloursArr={randomColours} connectedRaft={connectedRaft} />
                {!isLoading && <SimpleButton
                  onClick={onCancel}
                  title="No"
                  borderColour="red"
                  colour="white"
                  textColour="red"
                />}
                {!isLoading && <SimpleButton onClick={onYes} title="YES" />}
              </div>
            </div>}
          </Fragment>
        })}
      </div>
      <div className="verification-modal-bottom-btns-container">
        <SVGImageButton
          onClick={onScanAgain}
          title="SCAN AGAIN"
          SVGImage={RefreshSVG}
          backgroundColour={MAIN_BLUE}
          titleColour={WHITE}
          SVGColour={WHITE}
          disabled={isScanning}
        />
        <div className="dummy-gap"></div>
        <SimpleButton onClick={onCancel} title="CANCEL" />
      </div>
    </div>
  }

  return contentJSX;
}

export default VerificationModalPhoneApp;
