import RICInterface from "../RAFTInterface";
import { RaftTypeE } from "../../../types/raft";
import RAFT from "../RAFT";
import { RaftConnEvent, RaftPublishEvent, RaftSystemInfo, RaftUpdateEvent } from "@robotical/raftjs";
import { RaftInfoEvents } from "../../../types/events/raft-info";
import { RICLedLcdColours, RICStateInfo } from "@robotical/roboticaljs";

export class Marty extends RAFT implements RICInterface {
    type = RaftTypeE.MARTY;

    // RAFT State Info
    public raftStateInfo: RICStateInfo | null = null;

    // Marty System info
    public systemInfo: RaftSystemInfo | null = null;

    // Colours to use for LED patterns
    _ledLcdColours: RICLedLcdColours = [
        { led: "#202000", lcd: "#FFFF00" },
        { led: "#880000", lcd: "#FF0000" },
        { led: "#000040", lcd: "#0080FF" },
    ];

    // // Joint names
    // private _jointNames = {
    //     LeftHip: "LeftHip",
    //     LeftTwist: "LeftTwist",
    //     LeftKnee: "LeftKnee",
    //     RightHip: "RightHip",
    //     RightTwist: "RightTwist",
    //     RightKnee: "RightKnee",
    //     LeftArm: "LeftArm",
    //     RightArm: "RightArm",
    //     Eyes: "Eyes",
    // };

    private rssiValues: number[] = []; // Array to store recent RSSI values
    private MAX_RSSI_VALUES_N = 8; // Number of values to consider for averaging
    constructor(
        public id: string,
    ) {
        super(id);
    }

    get ledLcdColours() {
        return this._ledLcdColours;
    }

    /**
    * Gets the RSSI of the RAFT
    */
    getRSSI() {
        const newRssi = this.raftStateInfo?.robotStatus.robotStatus.bleRSSI || -200;
        if (this.rssiValues.length >= this.MAX_RSSI_VALUES_N) {
            this.rssiValues.shift();
        }
        this.rssiValues.push(newRssi);
        const rssiAverage = this.rssiValues.reduce((sum, val) => sum + val, 0) / this.rssiValues.length; 
        return rssiAverage;
    }

    /**
     * Gets the battery strength of the RAFT
     */
    getBatteryStrength() {
        return this.raftStateInfo?.power.powerStatus.battRemainCapacityPercent || 0;
    }

    /**
     * Highlights Marty by flashing the LED and playing a tune
     */
    async highlight() {
        await this.sendRestMessage('led/LEDeye/color/#ff8585');
        await this.sendRestMessage('filerun/arcade-beep.mp3');
        await new Promise((resolve) => setTimeout(resolve, 100));
        await this.sendRestMessage('led/LEDeye/color/#000000');
    }


    /**
    * This methods handles RAFT events coming from the RICConnector of the wrapper
    */
    handleRaftEvent(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        eventData: any
    ) {
        super.handleRaftEvent(eventType, eventEnum, eventName, eventData);
        switch (eventType) {
            case "pub":
                this.pubEventHandler(eventEnum as RaftPublishEvent, eventName, eventData);
                break;
            case "raftinfo":
                this.raftInfoEventHandler(eventEnum as RaftInfoEvents, eventName, eventData);
                break;
            default:
                break;
        }
    }

    /**
     * Pub Event Handler
     */
    async pubEventHandler(
        eventEnum: RaftPublishEvent,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            case RaftPublishEvent.PUBLISH_EVENT_DATA:
                break;
            default:
                break;
        }
    }

    /**
     * Raft Info Event Handler
     */
    async raftInfoEventHandler(
        eventEnum: RaftInfoEvents,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            default:
                break;
        }
    }


}

export default Marty;