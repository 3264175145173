import { addHoverClass } from '../../../utils/hoverClass';
import styles from './styles.module.css';
import { IconType } from 'react-icons/lib';

type NavButtonProps = {
  title: string;
  Icon: IconType | React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  iconColor?: string;
  onClick: () => void;
  resizeTitle?: boolean;
  isSelected?: boolean;
};

const NavButton = ({ title, Icon, iconColor, onClick, resizeTitle, isSelected }: NavButtonProps) => {
  return (
    <div className={[addHoverClass(styles.navButtonOuterContainer, styles.navButtonOuterContainerWithHover), isSelected && isSelected ? styles.navButtonSelected : ""].join(" ")}>
      <div className={styles.navButtonContainer} onClick={onClick}>
        <div className={styles.navButtonImageContainer}>
          <Icon color={iconColor || "#ffffff"} className={addHoverClass(styles.navButtonIcon, styles.navButtonIconWithHover)} />
        </div>
        <p className={resizeTitle ? styles.navButtonTitleResize : styles.navButtonTitle}>
          {title}
        </p>
      </div>
    </div>
  );
};

export default NavButton;