/**
 * AnalyticsManager
 * 
 * This class is responsible for managing the analytics services for the testing phase.
 * Analytics gathered:
 * - Connections
 * - Disconnections
 * - Visited pages
 * - emoji feedback
 * - text feedback
 * 
 * Each of them will be stored as an event. They will stored in the order they were received so we can track the user's journey.
 * There will be a timestamp for each event.
 * 
 * We store client's id in a cookie so we can track the user's journey.
 */


type EventType = "connection" | "disconnection" | "page" | "emojifeedback" | "textfeedback" | "connection_issue" | "connection_issue_resolved";

export class AnalyticsManager {
    private static instance: AnalyticsManager;
    private clientId: string;
    private eventsStorer: EventsStorer;
    private constructor() {
        this.clientId = this.getClientId();
        this.eventsStorer = new EventsStorer(this.clientId);
    }

    public static getInstance(): AnalyticsManager {
        if (!AnalyticsManager.instance) {
            AnalyticsManager.instance = new AnalyticsManager();
        }
        return AnalyticsManager.instance;
    }

    private getClientId(): string {
        let clientId = localStorage.getItem('@robotical/webapp/analytics/clientId');
        if (!clientId) {
            clientId = this.generateClientId();
            localStorage.setItem('@robotical/webapp/analytics/clientId', clientId);
        }
        return clientId;
    }

    private generateClientId(): string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    public logEvent(eventType: EventType, data: any): void {
        const event = new AnalyticsEvent(eventType, data);
        this.eventsStorer.addEvent(event);
    }
}

class AnalyticsEvent {
    public isProcessed = false;
    public timestamp: string;
    public urlSafeTimestamp: string;

    constructor(
        public type: EventType,
        public data: any
    ) {
        this.timestamp = new Date().toString();
        this.urlSafeTimestamp = new Date().toISOString().replaceAll("T", "_").replaceAll(":", "-").replaceAll("/", "-").replaceAll(".", "_");
    }

    public process(): void {
        this.isProcessed = true;
    }
}

class EventsStorer {
    private events: AnalyticsEvent[] = [];
    private processing = false;

    constructor(private clientId: string) { }

    public addEvent(event: AnalyticsEvent): void {
        this.events.push(event);
        this._processEvents();
    }

    private async _processEvents(): Promise<void> {
        if (this.processing) return;
        this.processing = true;

        for (const event of this.events) {
            if (!event.isProcessed) {
                this._processEvent(event);
                await this._delay(1000); // 1 second debounce time
            }
        }

        this.processing = false;
    }

    private _delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private _processEvent(event: AnalyticsEvent): void {
        event.process();
        const data = {
            ...event,
            ...event.data,
            data: undefined,
            isProcessed: undefined,
            urlSafeTimestamp: undefined
        };
        const path = `${this.clientId}/${event.urlSafeTimestamp}-${event.type}`;
        FirebaseInteractor.put(path, data);
    }
}


class FirebaseInteractor {
    static db_href = "https://app-v2-analytics-default-rtdb.firebaseio.com";

    static put(path: string, data: any): void {
        fetch(`${this.db_href}/${path}.json`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}