export enum RaftConnectionMethod {
     WEB_SOCKET = "WebSocket",
     WEB_BLE = "WebBLE",
     PHONE_BLE = "PhoneBLE",
};

export type ConnectionAttemptResults = {
     success: boolean;
     reason: 'failed' | 'unplugged' | 'success' | 'no_sys_info' | 'invalid_raft_type';
     data?: { raftId: string; raftType: RaftTypeE }
};

export enum RaftTypeE {
     MARTY = "Marty",
     COG = "Cog",
     undefined = "undefined",
};
