import { RaftObserver } from "../application/RAFTs/RaftObserver";
import { RaftInfoEvents } from "../types/events/raft-info";
import { WrapperSentMessage } from "../types/communication-between-apps/wrapper-communication";
import { ConnectionAttemptResults, RaftConnectionMethod, RaftTypeE } from "../types/raft";
import Connector from "./connectors/Connector";
import Logger from "../services/logger/Logger";
import { RaftConnEvent, RaftPublishEvent, RaftUpdateEvent } from "@robotical/raftjs";
import { RICLedLcdColours } from "@robotical/roboticaljs";
import ConnectorFactory from "./connectors/ConnectorFactory";

const SHOW_LOGS = true;
const TAG = "WrapperAppManager";

export class WrapperAppManager {

    // Connectors 
    private connectors: { [key: string]: Connector } = {};

    // Observers
    private _observers: { [key: string]: Array<RaftObserver> } = {};

    constructor() {
    }

    /**
    * Connect to a RAFT
    *
    * @param {string} method - can be "WebBLE" or "WebSocket"
    * @param {string | object} locator - either a string (WebSocket URL) or an object (WebBLE)
    * @returns Promise<ConnectionAttemptResults>
    *
    */
    async connect(method: RaftConnectionMethod, uuids: string[]): Promise<ConnectionAttemptResults> {
        const connector = await ConnectorFactory.connectToRaftHelper(method, uuids);
        if (!connector) {
            Logger.warn(SHOW_LOGS, TAG, `Failed to connect to RAFT with method: ${method}`);
            return { success: false, reason: "failed" };
        }
        const connectionResults = await connector.connect();
        if (connectionResults.success) {
            this.connectors[connector.id] = connector;
            connectionResults.data = { raftId: connector.id, raftType: connector.type };
        } else {
            Logger.warn(SHOW_LOGS, TAG, `Failed to connect to RAFT with id: ${connector.id} and type: ${connector.type}`);
        }
        Logger.info(SHOW_LOGS, TAG, `Connected to RAFT with id: ${connector.id} and type: ${connector.type}`);
        return connectionResults;
    }

    /**
     * Re-triggers the connect event which was missed due to the connection logic
     */
    getMissedConnEvent(raftId: any) {
        if (this.connectors[raftId]) {
            this.connectors[raftId].eventHandler("conn", RaftConnEvent.CONN_CONNECTED, "connect", {});
            this.connectors[raftId].publish("conn", RaftConnEvent.CONN_CONNECTED, "connect", {});
        }
    }

    /**
     * Get the RAFT Name
     */
    getRaftName(raftId: string) {
        if (this.connectors[raftId]) {
            return this.connectors[raftId].getRaftName();
        }
        return "";
    }

    /**
     * Verify RAFT
     */
    verifyRaft(ledLcdColours: RICLedLcdColours, raftId: string) {
        if (!this.connectors[raftId]) {
            Logger.warn(SHOW_LOGS, TAG, `verifyRaft: No connector found for raftId: ${raftId}`);
            return false;
        }
        return this.connectors[raftId].verifyRaft(ledLcdColours);
    }

    /**
     * Stop verifying RAFT
     */
    stopVerifyingRaft(isCorrectRIC: boolean, raftId: string) {
        if (!isCorrectRIC) {
            this._removeRICConnector(raftId, 3000);
        }
        return this.connectors[raftId].stopVerifyingRaft(isCorrectRIC);
    }

    /**
     * Disconnect from a RAFT
     */
    disconnect(raftId: string) {
        this._removeRICConnector(raftId, 3000);
        return this.connectors[raftId].disconnect();
    }

    /**
     * Audio streaming
     */
    streamAudio(raftId: string, streamContents: Uint8Array, clearExisting: boolean, duration: number) {
        return this.connectors[raftId].streamAudio(streamContents, clearExisting, duration);
    }

    /**
     * Publish an event to all observers
     * Set in RICConnector as an event listener callback
     */
    publish(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        eventData: any
    ): void {
        window.WebAppCommunicator.sendMessageNoWait(WrapperSentMessage.RAFT_PUBLISHED_EVENT, { eventType, eventEnum, eventName, eventData });
        if (this._observers.hasOwnProperty(eventType)) {
            for (const observer of this._observers[eventType]) {
                observer.notify(eventType, eventEnum, eventName, eventData);
            }
        }
    }

    /**
     * Send a REST message to the RAFT
     */
    async sendRestMessage(msg: string, params: object | undefined, raftId: string) {
        return this.connectors[raftId].sendRestMessage(msg, params);
    }

    /**
     * Removes marty after a certain time
     * Removes from the martys list after x seconds so that we can still get the last event
     */
    _removeRICConnector(connectorId: string, timeout: number) {
        const martyRemovalTimeout = setTimeout(() => {
            delete this.connectors[connectorId];
            clearTimeout(martyRemovalTimeout);
        }, timeout);
    }
}

const wrapperAppManager = new WrapperAppManager();
// @ts-ignore : just for debugging
window.wrapperAppManager = wrapperAppManager;
export default wrapperAppManager;