// grid component that displays projects as cards
import styles from './styles.module.css';
import { useRef, useState } from 'react';

import GridItem, { GridItemProps } from './GridItem';
import DropdownWithCheckboxes from '../DropdownWithCheckboxes';

type GridProps = {
    projects: GridItemProps[];
};

export default function Grid({ projects }: GridProps) {
    const gridRef = useRef<HTMLDivElement>(null);

    const allTags = Array.from(new Set(projects.reduce<string[]>((acc, project) => {
        if (project.tags) {
            return [...acc, ...project.tags];
        }
        return acc;
    }, [])));

    const [selectedTags, setSelectedTags] = useState<string[]>(allTags);


    return <>
        <div
            ref={gridRef}
            className={`${styles.gridContainer}`}
        >
            {projects.map((project, index) => {
                let shouldDisplay;
                if (!project.tags) {
                    shouldDisplay = true;
                } else {
                    shouldDisplay = project.tags.some(tag => selectedTags.includes(tag));
                }

                if (!shouldDisplay) {
                    return null;
                }
                return (
                    <GridItem
                        type={project.type}
                        key={index}
                        title={project.title}
                        subtitle={project.subtitle}
                        href={project.href}
                        contentTags={project.contentTags}
                        onClick={project.onClick}
                        SvgIcon={project.SvgIcon}
                        SvgIconOnHover={project.SvgIconOnHover}
                        tags={project.tags}
                        isComingSoon={project.isComingSoon}
                    />
                )
            })}
        </div>
        {!!allTags.length && <div className={styles.filterByContainer}>
            <DropdownWithCheckboxes items={allTags} onCheckedChange={(checkedTags) => setSelectedTags(checkedTags)} />
        </div>}
    </>;
}
