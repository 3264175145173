import { AppSentMessage } from "../../types/communication-between-apps/wrapper-communication";

const SHOW_LOGS_GLOBAL = true;
class Logger {

    public static info(SHOW_LOGS: boolean, tag: string, message: string) {
        SHOW_LOGS_GLOBAL && SHOW_LOGS && console.log('\x1b[34m%s\x1b[0m',  `INFO: ${tag}`, message); // Blue color for info logs
    }

    public static error(SHOW_LOGS: boolean, tag: string, message: string) {
        SHOW_LOGS_GLOBAL && SHOW_LOGS && console.error('\x1b[31m%s\x1b[0m', `ERROR: ${tag}`, message); // Red color for error logs
    }

    public static debug(SHOW_LOGS: boolean, tag: string, message: string) {
        SHOW_LOGS_GLOBAL && SHOW_LOGS && console.debug('\x1b[35m%s\x1b[0m', `DEBUG: ${tag}`, message); // Magenta color for debug logs
    }

    public static warn(SHOW_LOGS: boolean, tag: string, message: string) {
        SHOW_LOGS_GLOBAL && SHOW_LOGS && console.warn('\x1b[33m%s\x1b[0m', `WARN: ${tag}`, message); // Yellow color for warning logs
    }

    public static phoneAppLog(SHOW_LOGS: boolean, tag: string, message: string) {
        SHOW_LOGS_GLOBAL && SHOW_LOGS && console.log('\x1b[32m%s\x1b[0m', `PHONE APP: ${tag}`, message); // Green color for phone app logs
        SHOW_LOGS_GLOBAL && window.wrapperCommunicator.sendMessageNoWait(AppSentMessage.LOG, { showLogs: SHOW_LOGS, tag, message });
    }
}
window.Logger = Logger;
export default Logger; 
