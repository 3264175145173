import { useNavigate } from "react-router-dom";
import NavLayout from "../../layout/Navigation/NavLayout";
import Codewheel from "@robotical/codewheel/dist/App";
import { createElement, useEffect } from "react";
import EmojiModal from "../../components/modals/EmojiModal";
import { emojiFeedbackModalState } from "../../state-observables/modal/ModalState";

function CodewheelScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    window.applicationManager.analyticsManager.logEvent("page", { page: "codewheel" });
  }, []);

  useEffect(() => {
    window.applicationManager.returnToMainApp = () => {
    const shouldExit = window.confirm("Are you sure you want to exit and return to the main app?");
    if (shouldExit) {
      navigate("/activities");
    }
  }
  }, []);

  const clickBackHandler = () => {
    navigate("/activities");
    const ModalComponent = createElement(EmojiModal, { otherInfoObject: { page: "codewheel" } });
    emojiFeedbackModalState.setModal(ModalComponent, "");
    emojiFeedbackModalState.updateModalProps({ withCloseButton: true });
  };

  return (
    <NavLayout onClickBack={clickBackHandler}>
      <Codewheel />
    </NavLayout>
  );
}

export default CodewheelScreen;
