import { RICRoboticalAddOns } from "@robotical/ricjs-robotical-addons";
import { RaftInfoEvents } from "../../../types/events/raft-info";
import Logger from "../../../services/logger/Logger";
import { ConnectionAttemptResults, RaftTypeE } from "../../../types/raft";
import Connector from "../Connector";
import ConnectorInterface from "../ConnectorInterface";
import {
    RaftConnEvent,
    RaftUpdateEvent,
    RaftPublishEvent,
} from "@robotical/raftjs";
import { ConnManager, PystatusMsgType, RICServoParamUpdater, RICSystemUtils, SystemTypeMarty, } from "@robotical/roboticaljs";
import { WrapperSentMessage } from "../../../types/communication-between-apps/wrapper-communication";

const SHOW_LOGS = true;
const TAG = "MartyConnector";

class MartyConnector extends Connector implements ConnectorInterface {
    type = RaftTypeE.MARTY;

    constructor(raftId: string, connManager: ConnManager) {
        super(raftId, connManager);
    }

    /**
     * Publish an event to all observers
     * Set in RICConnector as an event listener callback
     */
    publish(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        eventData: any
    ): void {
        if (eventType === "raftinfo") {
            // marty info do not need manipulation like Cog
        }
        window.WebAppCommunicator.sendMessageNoWait(WrapperSentMessage.RAFT_PUBLISHED_EVENT, { raftId: this.id, eventType, eventEnum, eventName, eventData });
        if (this._observers.hasOwnProperty(eventType)) {
            for (const observer of this._observers[eventType]) {
                observer.notify(eventType, eventEnum, eventName, eventData);
            }
        }
    }

    /**
     * Connect to a RAFT
     */
    async connect(): Promise<ConnectionAttemptResults> {

        // TODO 2022 - this code would normally run after LED pattern confirmed
        const raftSystemUtils = this.connManager.getConnector().getRaftSystemUtils()
        const sysInfoOk = await raftSystemUtils.getSystemInfo();
        if (!sysInfoOk) {
            // this.emit(RAFT_REJECTED)
            return { success: false, reason: 'no_sys_info' };
        }

        //  -----check if the raft is in unplugged mode------
        const pystatus = await this.sendRestMessage("pystatus");
        // if it's in unplugged mode update the relevant state
        // that shows a modal
        if (pystatus && (pystatus as unknown as PystatusMsgType).running === "screenfree.py") {
            Logger.warn(SHOW_LOGS, TAG, `Marty is in unplugged mode`);
            return { success: false, reason: 'unplugged' };
        }

        return { success: true, reason: 'success' };
    }

    /**
     * Disconnect from Marty
     */
    async disconnect(): Promise<boolean> {
        // this.sendRestMessage('blerestart');
        return await super.disconnect();
    }

    /**
     * Event handler 
     */
    async eventHandler(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        data: any
    ) {
        super.eventHandler(eventType, eventEnum, eventName, data);
        switch (eventType) {
            case "conn":
                this.connectionEventHandler(eventEnum as RaftConnEvent, eventName, data);
                break;
            case "update":
                this.updateEventHandler(eventEnum as RaftUpdateEvent, eventName, data);
                break;
            case "pub":
                this.pubEventHandler(eventEnum as RaftPublishEvent, eventName, data);
                break;
            default:
                break;
        }
    }

    /**
     * Connection Event Handler
     */
    async connectionEventHandler(
        eventEnum: RaftConnEvent,
        eventName: string,
        data: any
    ) {
        Logger.info(SHOW_LOGS, TAG, `Event: ${eventEnum} data: ${data}`);
        switch (eventEnum) {
            case RaftConnEvent.CONN_CONNECTED:
                // ignore
                break;
            case RaftConnEvent.CONN_VERIFIED_CORRECT:
                // registering addons
                const addOnManager = (this.connManager.getSystemType() as SystemTypeMarty).getAddOnManager();
                RICRoboticalAddOns.registerAddOns(addOnManager);

                // retrieve RIC system info
                const raftSystemUtils = this.connManager.getConnector().getRaftSystemUtils();
                const msgHandler = raftSystemUtils.getMsgHandler();
                const addonManager = (this.connManager.getSystemType() as SystemTypeMarty).getAddOnManager();
                const ricSystemUtils = new RICSystemUtils(msgHandler, addonManager);
                await ricSystemUtils.retrieveMartySystemInfo();

                // initialise servo param update so we start downloading servo parameter updates
                const spu = RICServoParamUpdater.getSingletonInstance(msgHandler);
                spu && spu.init();

                // servo parameters update
                const spuTwo = RICServoParamUpdater.getSingletonInstance();
                spuTwo && spuTwo.setRobotConnected(true);

                const shouldUseLegacySoktoMode = false // this.soundStreamingStats.shouldUseLegacySoktoMode(systemInfo);

                this.connManager.getConnector().setLegacySoktoMode(shouldUseLegacySoktoMode);
                // checking for servo faults (this will trigger reportMsgCallback)
                (this.connManager.getConnector().getSystemType() as SystemTypeMarty)?.getRICServoFaultDetector().atomicReadOperation();
                break;
            default:
                break;
        }
    }

    /**
     * Update Event Handler
     */
    async updateEventHandler(
        eventEnum: RaftUpdateEvent,
        eventName: string,
        data: any
    ) {
        Logger.info(SHOW_LOGS, TAG, `Event: ${eventEnum} data: ${data}`);
        switch (eventEnum) {
            case RaftUpdateEvent.UPDATE_APP_UPDATE_REQUIRED:
                // do something
                break;
            default:
                break;
        }
    }

    /**
     * Pub Event Handler
     */
    async pubEventHandler(
        eventEnum: RaftPublishEvent,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            case RaftPublishEvent.PUBLISH_EVENT_DATA:
                const systemType = this.connManager.getConnector().getSystemType() as SystemTypeMarty;
                if (systemType) {
                    const stateInfo = systemType.getStateInfo();
                    this.publish("raftinfo", RaftInfoEvents.STATE_INFO, "StateInfo", { stateInfo });
                }
                break;
            default:
                break;
        }
    }
}

export default MartyConnector;
