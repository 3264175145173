import { AppSentMessage } from "../../types/communication-between-apps/wrapper-communication";
import { ConnectionAttemptResults, RaftConnectionMethod, RaftTypeE } from "../../types/raft";
import InUnpluggedModalContent from "../../components/modals/InUnplugged";
import modalState from "../../state-observables/modal/ModalState";
import { RaftInfoEvents } from "../../types/events/raft-info";
import { FOUND_RAFT_ON_DISCOVERY_RESPONSE } from "../../types/phone-app-communicator";
import RICInterface from "./RAFTInterface";
import { RaftObserver } from "./RaftObserver";
import {
    RaftConnEvent,
    RaftUpdateEvent,
    RaftPublishEvent,
    RaftOKFail,
    RaftSystemInfo,
} from "@robotical/raftjs";
import { RICLedLcdColours, RICStateInfo } from "@robotical/roboticaljs";
import { SimplifiedCogStateInfo } from "@robotical/roboticaljs/dist/SystemTypeCog/CogTypes";

export default class RAFT implements RICInterface {
    type = RaftTypeE.undefined;
    _ledLcdColours: RICLedLcdColours = [{ led: "#000000", lcd: "#000000" }];
    // Observers
    protected _observers: { [key: string]: Array<RaftObserver> } = {};

    // Is the RAFT serial number registered in warranty
    public isSerialNoRegisteredInWarranty: boolean | null = null;

    // System Info
    public systemInfo: RaftSystemInfo | null = null;

    // RAFT State Info
    public raftStateInfo: RICStateInfo | SimplifiedCogStateInfo | null = null;

    /**
     * Method to get ledLcdColours, to be implemented in child classes.
     */
    get ledLcdColours() {
        throw new Error("RAFT.tsx: ledLcdColours: Method should be implemented in child class");
        return this._ledLcdColours;
    }


    constructor(
        public id: string,
    ) { }


    /**
     * Check if RAFT is connected
     * Sends a message to the Wrapper RICConnector and waits for a response
     */
    async getIsConnectedFresh(): Promise<boolean> {
        const isConnected = await window.wrapperCommunicator.sendMessageAndWait<boolean>(AppSentMessage.RAFT_IS_CONNECTED, { raftId: this.id });
        return isConnected;
    }

    /**
    * Connect to a RAFT
    * We first connect, then we discover the raft type, and then we create the appopriate raft instance
    */
    static async connect(method: RaftConnectionMethod, uuids: string[]): Promise<ConnectionAttemptResults> {
        const connectResults = await window.wrapperCommunicator.sendMessageAndWait<ConnectionAttemptResults>(AppSentMessage.RAFT_CONNECT, { method, uuids });
        return connectResults;
    }

    /**
     * Disconnect from a RAFT
     */
    async disconnect() {
        return window.wrapperCommunicator.sendMessageAndWait<boolean>(AppSentMessage.RAFT_DISCONNECT, { raftId: this.id });
    }

    /**
     * Get RAFT name
     */
    async getRaftName() {
        const raftName = await window.wrapperCommunicator.sendMessageAndWait<string>(AppSentMessage.RAFT_GET_NAME, { raftId: this.id });
        return raftName;
    }

    /**
    * Verify correct RAFT is selected for phone app
    */
    async verifyRaftPhoneApp(discoveredDevice: FOUND_RAFT_ON_DISCOVERY_RESPONSE['foundRIC']) {
        return window.wrapperCommunicator.sendMessageAndWait<boolean>(AppSentMessage.RAFT_VERIFY_PHONE, { ledLcdColours: this.ledLcdColours, discoveredDevice, raftId: this.id });
    }

    /**
    * Verify correct RAFT is selected
    */
    async verifyRaft() {
        return window.wrapperCommunicator.sendMessageAndWait<boolean>(AppSentMessage.RAFT_VERIFY, { ledLcdColours: this.ledLcdColours, raftId: this.id });
    }

    /**
     * Stops the verification process
     */
    async stopVerifyingRaft(isCorrectRIC: boolean) {
        return window.wrapperCommunicator.sendMessageAndWait<boolean>(AppSentMessage.RAFT_STOP_VERIFY, { isCorrectRIC, raftId: this.id });
    }

    /**
     * Receiveds events from the RICConnector
     */
    receivedRICEvent(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        eventData: any
    ) {
        this.publish(eventType, eventEnum, eventName, eventData);
        this.handleRaftEvent(eventType, eventEnum, eventName, eventData);
    }

    /**
     * Send a REST message to the RAFT
     */
    async sendRestMessage(msg: string, params?: object): Promise<RaftOKFail> {
        return window.wrapperCommunicator.sendMessageAndWait<RaftOKFail>(AppSentMessage.RAFT_SEND_REST, { raftId: this.id, msg, params });
    }

    /**
     * Highlights the raft
     */
    async highlight() {
        throw new Error("'highlight' Method should be implemented in child class");
    }

    /**
     * Publishing events to observers
     * It could be either events we get from RAFT, or custom events related to the app
     */
    publish(
        eventType: string,
        eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents,
        eventName: string,
        eventData: any
    ): void {
        if (this._observers.hasOwnProperty(eventType)) {
            for (const observer of this._observers[eventType]) {
                observer.notify(eventType, eventEnum, eventName, eventData);
            }
        }
    }

    // RAFT observer
    subscribe(observer: RaftObserver, topics: Array<string>): void {
        for (const topic of topics) {
            if (!this._observers[topic]) {
                this._observers[topic] = [];
            }
            if (this._observers[topic].indexOf(observer) === -1) {
                this._observers[topic].push(observer);
            }
        }
    }

    unsubscribe(observer: RaftObserver): void {
        for (const topic in this._observers) {
            if (this._observers.hasOwnProperty(topic)) {
                const index = this._observers[topic].indexOf(observer);
                if (index !== -1) {
                    this._observers[topic].splice(index, 1);
                }
            }
        }
    }

    /**
    * Gets the RSSI of the RAFT
    */
    getRSSI() {
        throw new Error("Method will be implemented in child class");
        return 0;
    }

    /**
     * Gets the battery strength of the RAFT
     */
    getBatteryStrength() {
        throw new Error("Method will be implemented in child class");
        return 0;
    }

    /**
     * Gets the version of the RAFT
     */
    getRaftVersion(): string {
        return this.systemInfo?.SystemVersion || "";
    }

    /**
     * Gets the Serial Number of the RAFT
     */
    getSerialNumber(): string {
        return this.systemInfo?.SerialNo || "";
    }

    /**
     * Gets the Friendly name of the RAFT
     */
    getFriendlyName() {
        return this.systemInfo?.Friendly
    }

    /**
     * Stream audio to the RAFT
     */
    streamAudio(streamContents: Uint8Array, clearExisting: boolean, duration: number) {
        return window.wrapperCommunicator.sendMessageAndWait<boolean>(AppSentMessage.RAFT_STREAM_AUDIO, { raftId: this.id, streamContents: Array.from(streamContents), clearExisting, duration });
    }

    /**
     * Handles RAFT events 
     * (to be implemented in child classes)
     */
    handleRaftEvent(eventType: string, eventEnum: RaftConnEvent | RaftUpdateEvent | RaftPublishEvent | RaftInfoEvents, eventName: string, eventData: any) {
        switch (eventType) {
            case "conn":
                this._connectionEventHandler(eventEnum as RaftConnEvent, eventName, eventData);
                break;
            case "pub":
                this._pubEventHandler(eventEnum as RaftPublishEvent, eventName, eventData);
                break;
            case "raftinfo":
                this._raftInfoEventHandler(eventEnum as RaftInfoEvents, eventName, eventData);
                break;
            default:
                break;
        }
    }

    /**
     * Connection Event Handler
     */
    async _connectionEventHandler(
        eventEnum: RaftConnEvent,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            case RaftConnEvent.CONN_VERIFIED_CORRECT:
                window.applicationManager.analyticsManager.logEvent("connection", { raftType: this.type, systemInfo: this.systemInfo });
                break;
            case RaftConnEvent.CONN_DISCONNECTED: // this runs when the RAFT is disconnected on its own (due to a timeout or other reasons)
                window.applicationManager.disconnectGeneric(this, () => { }, true); // disconnects the RAFT and removes it from the connectedRafts list
                window.applicationManager.analyticsManager.logEvent("disconnection", { raftType: this.type });
                break;
            case RaftConnEvent.CONN_ISSUE_DETECTED:
                window.applicationManager.analyticsManager.logEvent("connection_issue", { data });
                break;
            case RaftConnEvent.CONN_ISSUE_RESOLVED:
                window.applicationManager.analyticsManager.logEvent("connection_issue_resolved", { data });
                break;
            default:
                break;
        }
    }

    /**
    * Pub Event Handler
    */
    async _pubEventHandler(
        eventEnum: RaftPublishEvent,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            case RaftPublishEvent.PUBLISH_EVENT_DATA:
                break;
            default:
                break;
        }
    }

    /**
     * Raft Info Event Handler
     */
    async _raftInfoEventHandler(
        eventEnum: RaftInfoEvents,
        eventName: string,
        data: any
    ) {
        switch (eventEnum) {
            case RaftInfoEvents.STATE_INFO:
                this.raftStateInfo = data.stateInfo;
                break;
            case RaftInfoEvents.SYSTEM_INFO:
                this.systemInfo = data.systemInfo;
                break;
            default:
                break;
        }
    }
}