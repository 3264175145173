import styles from "./styles.module.css";
import { createElement, memo } from "react";
import modalState, { emojiFeedbackModalState } from "../../../state-observables/modal/ModalState";
import { FunctionComponent, useState } from "react";
import iconDefault1 from './icon--marty-1.svg';
import iconDefault2 from './icon--marty-2.svg';
import iconDefault3 from './icon--marty-3.svg';
import iconDefault4 from './icon--marty-4.svg';
import iconDefault5 from './icon--marty-5.svg';
import icon1Hover from './icon--marty-hover-1.svg';
import icon2Hover from './icon--marty-hover-2.svg';
import icon3Hover from './icon--marty-hover-3.svg';
import icon4Hover from './icon--marty-hover-4.svg';
import icon5Hover from './icon--marty-hover-5.svg';
import SimpleButton from "../../disposables/buttons/SimpleButton";
import { RED } from "../../../styles/colors";
import DetailedFeedbackModal from "../DetailedFeedbackModal";

type Props = {
  otherInfoObject?: object;
};

export default function EmojiModal({ otherInfoObject }: Props) {
  const [showing, setShowing] = useState<"emoji" | "thankyou">("emoji");

  async function onEmojiFeedback(feedbackNumber: number) {
    window.applicationManager.analyticsManager.logEvent("emojifeedback", { feedback: feedbackNumber, ...otherInfoObject });
    setShowing("thankyou");
  }

  function handleYes() {
    emojiFeedbackModalState.closeModal(null);
    const FeedbackModal = createElement(DetailedFeedbackModal, { otherInfoObject });
    modalState.setModal(FeedbackModal, "Tell us more");
  }
  function handleNo() {
    emojiFeedbackModalState.closeModal(null);
  }

  return (
    <div className={styles.emojiFeedbackContainer}>
      {showing === "emoji" && <>
        <EmojiIcon defaultIcon={iconDefault1} hoverIcon={icon1Hover} onClick={() => onEmojiFeedback(1)} />
        <EmojiIcon defaultIcon={iconDefault2} hoverIcon={icon2Hover} onClick={() => onEmojiFeedback(2)} />
        <EmojiIcon defaultIcon={iconDefault3} hoverIcon={icon3Hover} onClick={() => onEmojiFeedback(3)} />
        <EmojiIcon defaultIcon={iconDefault4} hoverIcon={icon4Hover} onClick={() => onEmojiFeedback(4)} />
        <EmojiIcon defaultIcon={iconDefault5} hoverIcon={icon5Hover} onClick={() => onEmojiFeedback(5)} />
      </>}
      {showing === "thankyou" && <div>
        <h1 style={{ textAlign: "center" }}>Thank you 😊</h1>
        <p>Would you like to tell us a bit more?</p>
        <div className={styles.buttonsContainer}>
          <SimpleButton onClick={handleYes} title="Yes" />
          <SimpleButton onClick={handleNo} title="No" colour={RED} />
        </div>
      </div>}
    </div>
  );
}

type EmojiIconProps = {
  defaultIcon: string;
  hoverIcon: string;
  onClick: () => void;
};

const EmojiIcon: FunctionComponent<EmojiIconProps> = memo(({ defaultIcon, hoverIcon, onClick }) => {
  const [Icon, setIcon] = useState<string>(defaultIcon);

  return (
    <div
      className={styles.emojiFeedbackButton}
      onClick={onClick}
      onMouseEnter={() => setIcon(hoverIcon)}
      onMouseLeave={() => setIcon(defaultIcon)}
    >
      <img src={Icon} alt="emoji" className={styles.emojiFeedbackIcon} />
    </div>
  );
});

