import isVersionGreater from "./compare-version";
import RAFT from "../../application/RAFTs/RAFT";

export const FW_VERSION_RSSI_WIFI = "1.2.0"; // greater versions than this (1.1.17) have the wifi rssi logic
export default function wifiConfigSub(raft: RAFT) {
  if (!isVersionGreater(raft.getRaftVersion(), FW_VERSION_RSSI_WIFI)) {
    return "Currently, wifi configuration only works in the phone application.";
  }
  return "";
  // const isPaused = window.marty.getCachedWifiStatus().ssid ? true : false;
  // const conInfo =
  //   window.marty.getCachedWifiStatus().connState ===
  //     RICWifiConnState.WIFI_CONN_CONNECTED ||
  //   window.marty.getCachedWifiStatus().ssid
  //     ? window.marty.getCachedWifiStatus().ipAddress +
  //       " on " +
  //       window.marty.getCachedWifiStatus().ssid
  //     : null;

  // const isConnected =
  //   window.marty.getCachedWifiStatus().connState ===
  //   RICWifiConnState.WIFI_CONN_CONNECTED;

  // let subtitle;

  // if (isConnected) {
  //   subtitle = "Connected";
  // }
  // if (isPaused) {
  //   subtitle = "Connected (Paused for BLE)";
  // }
  // if (!isConnected && !isPaused) {
  //   subtitle = "Not Connected";
  // }
  // if (conInfo) {
  //   subtitle += " | " + conInfo;
  // }

  // return subtitle;
}
