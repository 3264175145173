export interface IAppSentMessage {
    message: AppSentMessage;
    data?: any;
    messagePromiseId: string;
}

export interface IWrapperSentMessage {
    message: WrapperSentMessage;
    data?: any;
    messagePromiseId: string;
}

export enum AppSentMessage {
    RAFT_IS_CONNECTED = "RAFT_IS_CONNECTED",
    RAFT_CONNECT = "RAFT_CONNECT",
    RAFT_GET_NAME = "RAFT_GET_NAME",
    RAFT_VERIFY = "RAFT_VERIFY",
    RAFT_VERIFY_PHONE = "RAFT_VERIFY_PHONE",
    RAFT_STOP_VERIFY = "RAFT_STOP_VERIFY",
    RAFT_DISCONNECT = "RAFT_DISCONNECT",
    RAFT_START_DISCOVERY = "RAFT_START_DISCOVERY",
    RAFT_STOP_DISCOVERY = "RAFT_STOP_DISCOVERY",
    RAFT_SELECT = "RAFT_SELECT",
    RAFT_SEND_REST = "RAFT_SEND_REST",
    GET_MISSED_CONN_EVENT = "GET_MISSED_CONN_EVENT",
    RAFT_STREAM_AUDIO = "RAFT_STREAM_AUDIO",
    SAVE_FILE_ON_DEVICE = "SAVE_FILE_ON_DEVICE",
    SAVE_FILE_ON_DEVICE_LOCAL_STORAGE = "SAVE_FILE_ON_DEVICE_LOCAL_STORAGE",
    LOAD_FILE_FROM_DEVICE_LOCAL_STORAGE = "LOAD_FILE_FROM_DEVICE_LOCAL_STORAGE",
    DELETE_FILE_FROM_DEVICE_LOCAL_STORAGE = "DELETE_FILE_FROM_DEVICE_LOCAL_STORAGE",
    LIST_FILES_FROM_DEVICE_LOCAL_STORAGE = "LIST_FILES_FROM_DEVICE_LOCAL_STORAGE",
    LOG = "LOG",
    INJECT_JS = "INJECT_JS",
}

export enum WrapperSentMessage {
    RAFT_PUBLISHED_EVENT = "RAFT_PUBLISHED_EVENT",
    RAFT_CONNECTED_RESULTS = "RAFT_CONNECTED_RESULTS",
}

export interface WRAPPER_RESPONSE_BASE {
    success: boolean;
    error?: string;
    results?: any;
    messagePromiseId: string;
}

export interface WEB_APP_MESSAGE_BASE {
    success: boolean;
    error?: string;
    results?: any;
    messagePromiseId: string;
}


