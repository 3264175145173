import { useEffect, useRef } from "react";
import "./styles.css";

type LoadingSpinnerProps = {
  colourA?: string;
  colourB?: string;
};

export default function LoadingSpinner({
  colourA,
  colourB,
}: LoadingSpinnerProps) {
  const spinnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (spinnerRef.current) {
      if (colourA) {
        spinnerRef.current.style.setProperty("--border-colour", colourA);
      }
      if (colourB) {
        spinnerRef.current.style.setProperty("--border-colour", colourB);
      }
    }
  }, [spinnerRef.current]);
  
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner" ref={spinnerRef}></div>
    </div>
  );
}
